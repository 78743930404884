import synctrack from './syntrack.png';
import returnDrive from './returnDrive.png';
import avada from './avada.png';
import CTA from './CTA.png';
import orderTracking from './orderTracking.png';

export const recommendAppImage = {
  synctrack,
  returnDrive,
  avada,
  CTA,
  orderTracking,
};
