import RegularText from '@/components/RegularText';
import Switch from '@/components/Switch';
import { Enum, PATH } from '@/constants';
import { CriteriaHidePayment } from '@/constants/enum';
import options from '@/constants/options';
import { capitalizeFirstLetter, checkShowErrorInline, formatCreatedAt, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import hidePaymentSlice, { hidePaymentTableSelector, paymentDetailSelector } from '@/redux/slice/hidePayment.slice';
import { IResponseApi } from '@/types/api/response.api';
import {
  Button,
  EmptyState,
  IndexTable,
  Modal,
  Pagination,
  SkeletonBodyText,
  Tooltip,
  useIndexResourceState,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastSlice from '../../../../redux/slice/toast.slice';

interface TableHidePaymentsProps {
  onParentAction?: (action: () => void) => void;
}

const TableHidePayments = ({ onParentAction }: TableHidePaymentsProps): JSX.Element => {
  const scope = useScope();
  const dispatch = useDispatch();
  const handleCountry = useCountry();
  const navigate = useNavigate();
  const hidePaymentTable = useSelector(hidePaymentTableSelector);
  const paymentDetail = useSelector(paymentDetailSelector);

  const [state, setState] = useState({
    itemSelected: -1,
    isOpenModalDelete: false,
  });

  const { data, isFetching, isLoading } = apiCaller.useFetchHidePaymentListQuery({
    ...hidePaymentTable,
  });
  const [toggleHidePayment, toggleHidePaymentStatus] = apiCaller.useStatusHidePaymentRulesMutation();
  const [RemoveItem, RemoveItemStatus] = apiCaller.useDeleteHidePaymentRulesMutation();
  const handleCloseModalDelete = useCallback(() => {
    setState({
      itemSelected: -1,
      isOpenModalDelete: false,
    });
  }, []);

  const handleOpenModalDelete = useCallback(
    (id: number) => () => {
      setState({
        itemSelected: id,
        isOpenModalDelete: true,
      });
    },
    [],
  );

  const handleEditItem = useCallback(
    (item: IResponseApi.IHidePaymentItem) => () => {
      dispatch(
        hidePaymentSlice.actions.handleChangeSelectedCondition(
          options.criteriaToConditionMapping[item.criteria as CriteriaHidePayment],
        ),
      );

      dispatch(
        hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );

      const payload = {
        id: item.id,
        criteria: item.criteria,
        startDate: item.value?.split(';')[0],
        endDate: item.value?.split(';')[1],
        country: item.countryCode ? [item.countryCode] : [],
        value: item.value,
        paymentMethodsApply: item.paymentMethodsApply,
        enabledExpressCheckout: item.enabledExpressCheckout,
        condition: item.condition,
        expressMethodsApply: item.expressMethodsApply,
        isActive: item.isActive,
        createdAt: item.createdAt,
        lastUpdatedAt: item.lastUpdatedAt,
      };

      dispatch(hidePaymentSlice.actions.handleSettingHidePayment(payload));
      dispatch(hidePaymentSlice.actions.changeItemBackupHidePayment(payload));

      navigate(PATH.HIDE_PAYMENT_DETAIL);
    },
    [dispatch, navigate],
  );

  const toggleRule = useCallback(
    (id: number) => (status: boolean) => {
      toggleHidePayment({
        ids: [id],
        isActive: status,
      });
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: status ? 'Turn on' : 'Turn off',
          error: false,
        }),
      );
    },
    [toggleHidePayment, dispatch],
  );

  const items = useMemo(() => {
    return data?.rules.map((hidePaymentItem) => {
      const renderDescription = () => {
        const conditionDayOfWeek = hidePaymentItem.criteria === Enum.CriteriaHidePayment.DayOfWeek;
        const conditionLocation = hidePaymentItem.criteria === Enum.CriteriaHidePayment.Country && !hidePaymentItem.countryCode;
        const valueDayOfWeek = hidePaymentItem.value?.split(';');
        const data = [
          hidePaymentItem.name,
          conditionDayOfWeek
            ? `${capitalizeFirstLetter(valueDayOfWeek[0])} to ${capitalizeFirstLetter(valueDayOfWeek[1])}`
            : conditionLocation
            ? `${handleCountry.renderCountry(hidePaymentItem.value)} (${hidePaymentItem.value})`
            : hidePaymentItem.value === 'true'
            ? ''
            : hidePaymentItem.value,
          hidePaymentItem.timezone ? hidePaymentItem.timezone : null,
          hidePaymentItem.provinceName,
          hidePaymentItem.countryCode
            ? `${handleCountry.renderCountry(hidePaymentItem.countryCode)} (${hidePaymentItem.countryCode})`
            : undefined,
        ];
        const description = data.filter((item) => !!item).join(' - ');
        return description;
      };
      return {
        id: hidePaymentItem.id.toString(),
        criteria: (
          <RegularText>
            {options.criteriaHidePaymentFilter.find((item) => item.value === hidePaymentItem.criteria)?.label}
          </RegularText>
        ),
        description: <RegularText>{renderDescription()}</RegularText>,
        paymentMethod: <RegularText>{hidePaymentItem.paymentMethodsApply}</RegularText>,
        expressCheckout: (
          <RegularText>
            {hidePaymentItem.enabledExpressCheckout === Enum.StatusExpressCheckout.enable
              ? `${hidePaymentItem.expressMethodsApply}`
              : '---'}
          </RegularText>
        ),
        createdAt: <RegularText>{formatCreatedAt(hidePaymentItem.createdAt)}</RegularText>,
        lastUpdatedAt: (
          <RegularText>{hidePaymentItem.lastUpdatedAt ? formatCreatedAt(hidePaymentItem.lastUpdatedAt) : ''}</RegularText>
        ),
        action: (
          <div className={scope.isViewOnly ? 'btn-container disable' : 'btn-container'}>
            <div className="control-btn control-btn-toggle">
              <Switch
                onSwitch={(checked) => toggleRule(hidePaymentItem.id)(checked)}
                isActive={hidePaymentItem.isActive}
                isLoading={toggleHidePaymentStatus.isLoading || scope.isViewOnly}
              />
            </div>
            <div className="absolute d-flex">
              <div className="control-btn edit-btn">
                <Tooltip content="Edit">
                  <Button icon={EditMinor} onClick={handleEditItem(hidePaymentItem)} variant="plain" />
                </Tooltip>
              </div>
              <div className="control-btn remove-btn">
                <Tooltip content="Delete">
                  <Button icon={DeleteMinor} onClick={handleOpenModalDelete(hidePaymentItem.id)} variant="plain" />
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [
    toggleHidePaymentStatus.isLoading,
    data?.rules,
    handleCountry,
    handleEditItem,
    handleOpenModalDelete,
    scope.isViewOnly,
    toggleRule,
  ]);

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items || []);

  onParentAction?.(clearSelection);

  const handleDelete = useCallback(
    (id: number) => () => {
      RemoveItem({
        ids: JSON.stringify([id]),
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));

          if (data && data.meta.totalResult % Number(hidePaymentTable.perPage) === 1) {
            dispatch(
              hidePaymentSlice.actions.handleHidePaymentTable({
                ...hidePaymentTable,
                page: hidePaymentTable.page - 1 || 1,
              }),
            );
          }
          clearSelection();
          handleCloseModalDelete();
        }
      });
    },

    [hidePaymentTable, data, RemoveItem, dispatch, handleCloseModalDelete, clearSelection],
  );

  const handleDeleteSelected = useCallback(async () => {
    try {
      const res = await RemoveItem({
        ids: JSON.stringify(selectedResources),
      });
      const condition = checkShowErrorInline(res);

      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        if (data?.meta.totalResult === selectedResources.length) {
          dispatch(
            hidePaymentSlice.actions.handleHidePaymentTable({
              ...hidePaymentTable,
              page: hidePaymentTable.page - 1 || 1,
            }),
          );
        }

        handleCloseModalDelete();
        clearSelection();
      }
    } catch (error) {
      console.error('Error while deleting selected resources:', error);
    }
  }, [hidePaymentTable, clearSelection, data?.meta.totalResult, RemoveItem, dispatch, handleCloseModalDelete, selectedResources]);

  const handleToggleSelected = useCallback(
    (status: boolean) => async () => {
      try {
        const res = await toggleHidePayment({
          ids: selectedResources.map((item) => +item),
          isActive: status,
        });

        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [toggleHidePayment, dispatch, selectedResources],
  );

  const promotedBulkActions = [
    {
      content: 'Cancel',
      onAction: () => clearSelection(),
    },
    {
      content: 'Turn on',
      onAction: handleToggleSelected(true),
    },
    {
      content: 'Turn off',
      onAction: handleToggleSelected(false),
    },
    {
      content: 'Delete',
      onAction: handleOpenModalDelete(-1),
    },
  ];

  const rowMarkup = useMemo(() => {
    return items?.map(
      ({ id, criteria, description, action, createdAt, lastUpdatedAt, paymentMethod, expressCheckout }, index) => (
        <IndexTable.Row onClick={() => {}} id={id} key={id} position={index} selected={selectedResources.includes(id)}>
          <IndexTable.Cell>{criteria}</IndexTable.Cell>
          <IndexTable.Cell>{description}</IndexTable.Cell>
          <IndexTable.Cell>{paymentMethod}</IndexTable.Cell>
          <IndexTable.Cell>{expressCheckout}</IndexTable.Cell>
          <IndexTable.Cell>{createdAt}</IndexTable.Cell>
          <IndexTable.Cell>{lastUpdatedAt}</IndexTable.Cell>
          <IndexTable.Cell>{action}</IndexTable.Cell>
        </IndexTable.Row>
      ),
    );
  }, [items, selectedResources]);

  const resourceName = {
    singular: 'rule',
    plural: 'rules',
  };

  return (
    <div className="pd-16">
      <div className="table-block table-block-blacklist">
        <IndexTable
          emptyState={
            isLoading ? (
              <SkeletonBodyText lines={16} />
            ) : (
              <EmptyState
                heading="Take control of your checkout security"
                action={{
                  content: 'Start with new rule',
                  onAction: () => {
                    dispatch(hidePaymentSlice.actions.handleOpenModalHidePayment(true));
                    dispatch(
                      hidePaymentSlice.actions.handleSettingHidePayment({
                        ...paymentDetail,
                      }),
                    );
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  },
                }}
                secondaryAction={{
                  content: 'Learn more',
                  url: 'https://docs.ipblocker.io/',
                  target: '_blank',
                }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              >
                <RegularText>Set up your blacklist now to safeguard your online space from potential threats</RegularText>
              </EmptyState>
            )
          }
          onSelectionChange={handleSelectionChange}
          resourceName={resourceName}
          itemCount={items?.length || 0}
          headings={[
            { title: 'Criteria' },
            { title: 'Description' },
            { title: 'Payment Method' },
            { title: 'Express Checkout' },
            { title: 'Created at' },
            { title: 'Last updated' },
            { title: 'Action' },
          ]}
          promotedBulkActions={promotedBulkActions}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        >
          {isLoading ? <SkeletonBodyText lines={16} /> : rowMarkup}
        </IndexTable>

        <div className="mt-16 pb-16">
          {data && data?.meta.totalResult ? (
            <Pagination
              label={
                data?.meta.totalResult
                  ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.perPage) + 1} to ${Math.min(
                      data.meta.currentPage * Number(data.meta.perPage),
                      data?.meta.totalResult,
                    )} of ${data?.meta.totalResult} items`
                  : null
              }
              hasPrevious={!isFetching && data && data.meta.currentPage > 1}
              onPrevious={() => {
                if (data) {
                  dispatch(
                    hidePaymentSlice.actions.handleHidePaymentTable({
                      ...hidePaymentTable,
                      page: data && data.meta.currentPage - 1,
                    }),
                  );
                }
              }}
              hasNext={
                !isFetching && data && data.meta.currentPage < Math.ceil(data.meta.totalResult / Number(data.meta.perPage))
              }
              onNext={() => {
                if (data) {
                  dispatch(
                    hidePaymentSlice.actions.handleHidePaymentTable({
                      ...hidePaymentTable,
                      page: data.meta.currentPage + 1,
                    }),
                  );
                }
              }}
            />
          ) : null}
        </div>
      </div>

      <Modal
        open={state.isOpenModalDelete}
        onClose={handleCloseModalDelete}
        title={state.itemSelected === -1 ? 'Do you want to delete all selected rules' : 'Delete rule'}
        primaryAction={{
          destructive: true,
          content: 'Delete',
          onAction: state.itemSelected === -1 ? handleDeleteSelected : handleDelete(state.itemSelected),
          loading: RemoveItemStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModalDelete,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>If you delete the rule, you won't be able to revert it.</RegularText>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default memo(TableHidePayments);
