import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IParamsApi } from '@/types/api/params.api';
import { Enum } from '@/constants';
import { ActionType, CriteriaType } from '@/constants/enum';

export interface IItemBlock {
  id?: number;
  criteria: string | CriteriaType;
  linkRedirect?: string;
  shortUrl?: string;
  priority: string;
  country?: Array<string>;
  state?: Array<string>;
  city?: Array<string>;
  referUrl?: string;
  shortReferUrl?: string;
  ipAddress?: string;
  note?: string;
  ispName?: Array<string>;
  ispCode?: Array<string>;
  collectionId?: Array<string>;
  productId?: Array<string>;
  pageId?: Array<string>;
  type: string;
  deviceType?: string;
  osName?: string;
  browserName?: Array<string>;
  isActive: boolean;
  productName?: string[];
  collectionName?: string[];
  pageTitle?: string[];
  createdAt?: number;
  lastUpdatedAt?: number;
  pathname?: string;
}
export interface IErrorRule {
  criteria?: CriteriaType;
  error?: string | React.ReactElement;
}
interface State {
  blackListTable: IParamsApi.IGetSettingList;
  isOpenGuide: boolean;
  inputSearch: string;
  inputIsp: string;
  inputProduct: string;
  inputSpecificPage: string;
  setting: IItemBlock;
  settingBackup: IItemBlock;
  tabSelected: number;
  tabSelectedModal: number;
  errorRule: IErrorRule[];
}

// Define the initial state using that type
export const initialState: State = {
  errorRule: [],
  tabSelected: Enum.SelectedTab.Blacklist,
  tabSelectedModal: Enum.SelectedTabModal.Blacklist,
  blackListTable: {
    search: '',
    type: '',
    criteria: '',
    perPage: '10',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'createdAt',
    priority: Enum.ListType.BlackList,
  },
  isOpenGuide: false,
  inputSearch: '',
  inputIsp: '',
  inputProduct: '',
  inputSpecificPage: '',
  setting: {
    isActive: true,
    type: ActionType.Block,
    criteria: '-1',
    id: undefined,
    linkRedirect: '',
    shortUrl: '',
    referUrl: '',
    shortReferUrl: '',
    note: '',
    priority: Enum.ListType.BlackList,
    collectionId: [],
    state: [],
    city: [],
    country: [],
    ipAddress: '',
    ispName: [],
    ispCode: [],
    productId: [],
    pageId: [],
    deviceType: '',
    osName: '',
    browserName: [],
    productName: [],
  },
  settingBackup: {
    isActive: true,
    type: ActionType.Block,
    criteria: '-1',
    id: undefined,
    linkRedirect: '',
    shortUrl: '',
    referUrl: '',
    shortReferUrl: '',
    note: '',
    priority: Enum.ListType.BlackList,
    collectionId: [],
    state: [],
    city: [],
    country: [],
    ipAddress: '',
    ispName: [],
    ispCode: [],
    productId: [],
    pageId: [],
    deviceType: '',
    osName: '',
    browserName: [],
  },
};

export const blockCheckoutAccessSlice = createSlice({
  name: 'blockCheckoutAccess',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTabSelected: (state, action: PayloadAction<number>) => {
      state.tabSelected = action.payload;
    },
    handleTabSelectedModal: (state, action: PayloadAction<number>) => {
      state.tabSelectedModal = action.payload;
    },
    handleBlackListTable: (state, action: PayloadAction<IParamsApi.IGetSettingList>) => {
      state.blackListTable = action.payload;
    },
    handleOpenGuide: (state, action: PayloadAction<boolean>) => {
      state.isOpenGuide = action.payload;
    },
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    handleInputIsp: (state, action: PayloadAction<string>) => {
      state.inputIsp = action.payload;
    },
    handleInputProduct: (state, action: PayloadAction<string>) => {
      state.inputProduct = action.payload;
    },
    handleInputSpecificPage: (state, action: PayloadAction<string>) => {
      state.inputSpecificPage = action.payload;
    },
    clearSetting: (state) => {
      state.setting = {
        isActive: true,
        type: ActionType.Block,
        criteria: '-1',
        id: undefined,
        linkRedirect: '',
        shortUrl: '',
        referUrl: '',
        shortReferUrl: '',
        note: '',
        priority: Enum.ListType.BlackList,
        collectionId: [],
        state: [],
        city: [],
        country: [],
        ipAddress: '',
        ispName: [],
        ispCode: [],
        productId: [],
        pageId: [],
        deviceType: '',
        osName: '',
        browserName: [],
        productName: [],
      };
      state.inputIsp = '';
      state.inputProduct = '';
      state.inputSpecificPage = '';
    },
    clearInputIsp: (state) => {
      state.inputIsp = '';
    },
    clearInputProduct: (state) => {
      state.inputProduct = '';
    },
    clearInputSpecificPage: (state) => {
      state.inputSpecificPage = '';
    },
    handleSetting: (state, action: PayloadAction<IItemBlock>) => {
      state.setting = action.payload;
    },
    handleSettingBackup: (state, action: PayloadAction<IItemBlock>) => {
      state.settingBackup = action.payload;
    },
    handleErrorRule: (state, action: PayloadAction<IErrorRule[]>) => {
      state.errorRule = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const blackListTableSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.blackListTable,
);
export const tabSelectedSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.tabSelected,
);
export const tabSelectedModalSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.tabSelectedModal,
);
export const settingSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.setting,
);

export const settingBackupSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.settingBackup,
);

export const inputSearchBlockAccessSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.inputSearch,
);

export const inputIspSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.inputIsp,
);

export const inputProductSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.inputProduct,
);
export const inputSpecificPageSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.inputSpecificPage,
);

export const openGuideSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.isOpenGuide,
);

export const errorRuleSelector = createSelector(
  (state: RootState) => state.blockCheckoutAccess,
  (state) => state.errorRule,
);

export default blockCheckoutAccessSlice;
