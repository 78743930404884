import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { BREAKPOINT } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import settingsSlice, { cssSelector } from '@/redux/slice/settings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { ISettings } from '@/types/components';
import { Collapsible, Divider, Icon, InlineGrid, Modal } from '@shopify/polaris';
import { ChevronDownMinor, ChevronRightMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const Template = () => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.MD });
  const dispatch = useDispatch();
  const [activeTemplate, setActiveTemplate] = useState<string | null>(null);
  const [modal, setModal] = useState({
    status: false,
    id: 0,
  });
  const templates = apiCaller.useGetTemplatesQuery(config?.shop);
  const [fetchTemplateDetail] = apiCaller.useLazyFetchBlockingTemplateQuery();
  const customCss = useSelector(cssSelector);
  const handleToggle = useCallback((id: string) => {
    setActiveTemplate((currentActive) => (currentActive === id ? null : id));
  }, []);

  const handleCardClick = async () => {
    try {
      const { data: dataTemplate } = await fetchTemplateDetail({ templateId: modal.id });
      if (dataTemplate) {
        const template: ISettings.CustomTemplate = {
          title: {
            active: dataTemplate.properties.title?.active || false,
            text: dataTemplate.properties.title?.text || '',
            color: dataTemplate.properties.title?.color || '',
          },
          description: {
            active: dataTemplate.properties.description?.active || false,
            text: dataTemplate.properties.description?.text || '',
            color: dataTemplate.properties.description?.color || '',
          },
          background: {
            active: dataTemplate.properties.background?.active || false,
            colorFrom: dataTemplate.properties.background?.colorFrom || null,
            colorTo: dataTemplate.properties.background?.colorTo || null,
            type: dataTemplate.properties.background?.type || 0,
            value: dataTemplate.properties.background?.value || '',
          },
          superTitle: {
            active: dataTemplate.properties.superTitle?.active || false,
            text: dataTemplate.properties.superTitle?.text || '',
            color: dataTemplate.properties.superTitle?.color || '',
          },
          logoImage: {
            active: dataTemplate.properties?.logoImage?.active || false,
            value: dataTemplate.properties?.logoImage?.value || '',
          },
        };
        dispatch(settingsSlice.actions.handleTemplate(template));
        dispatch(settingsSlice.actions.handleTemplateId(modal.id));
        dispatch(
          settingsSlice.actions.handleInput({
            titleColor: template.title.color,
            backgroundColor: template.background.value,
            contentColor: template.description.color,
            superTitleColor: template.superTitle.color,
            backgroundGradientFrom: template.background.colorFrom || '',
            backgroundGradientTo: template.background.colorTo || '',
          }),
        );
        dispatch(settingsSlice.actions.handleCss(dataTemplate.customCss));
        dispatch(settingsSlice.actions.handleCssBackup(customCss));
        setModal({
          id: 0,
          status: false,
        });
      }
    } catch {
      setModal({
        id: 0,
        status: false,
      });
      dispatch(
        toastSlice.actions.handleToast({
          isOpen: true,
          content: 'Save failure',
          error: false,
        }),
      );
    }
  };

  return (
    <InlineGrid columns={isMobile ? 3 : 1} gap={'200'}>
      {templates.data &&
        templates.data?.listTemplates?.map((item) => {
          const isOpen = activeTemplate === item.id.toString();

          return (
            <div key={item.id} className="template-container">
              {isMobile ? (
                <div>
                  <BoldText>{item.templateName}</BoldText>
                  <div
                    className="template-detail"
                    onClick={() => {
                      setModal({
                        id: item.id,
                        status: true,
                      });
                    }}
                  >
                    <img src={item.templateImage} alt="template" className="template-img" />
                    <div className="btn-selected" />
                  </div>
                </div>
              ) : (
                <div>
                  <div onClick={() => handleToggle(item.id.toString())} className="template-name">
                    <BoldText>{item.templateName}</BoldText>
                    <div className="btn-chevron">
                      <Icon source={isOpen ? ChevronDownMinor : ChevronRightMinor}></Icon>
                    </div>
                  </div>
                  <Divider borderColor="border" />
                  <Collapsible
                    open={isOpen}
                    id={item.id.toString()}
                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                    expandOnPrint
                  >
                    <div
                      className="template-detail"
                      onClick={() => {
                        setModal({
                          id: item.id,
                          status: true,
                        });
                      }}
                    >
                      <img src={item.templateImage} alt="template" className="template-img" />
                      <div className="btn-selected" />
                    </div>
                  </Collapsible>
                </div>
              )}
            </div>
          );
        })}
      <Modal
        onClose={() =>
          setModal({
            id: 0,
            status: false,
          })
        }
        open={modal.status}
        title="Change template?"
        sectioned
        primaryAction={{
          content: 'Change',
          onAction: handleCardClick,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () =>
              setModal({
                id: 0,
                status: false,
              }),
          },
        ]}
      >
        <RegularText>
          <b>Style</b> and <b>Custom CSS</b> will adapt according to the template you choose.
        </RegularText>
      </Modal>
    </InlineGrid>
  );
};

export default memo(Template);
