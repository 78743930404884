import { PATH } from '@/constants';
import { Button } from '@shopify/polaris';
import { SocialPostMajor } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';

function ButtonSupport() {
  const navigate = useNavigate();
  const handleSupport = () => {
    navigate(PATH.FEEDBACK);
  };
  return (
    <div>
      <Button icon={SocialPostMajor} onClick={handleSupport}>
        Request feature
      </Button>
    </div>
  );
}

export default ButtonSupport;
