import CustomButton from '@/components/CustomButton';
import Layout from '@/components/layout';
import RegularText from '@/components/RegularText';
import { Enum, PATH } from '@/constants';
import { ActionType, BREAKPOINT, CriteriaType, ListType } from '@/constants/enum';
import options from '@/constants/options';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import blockListSlice, {
  blackListTableSelector,
  openGuideSelector,
  settingSelector,
  tabSelectedModalSelector,
  tabSelectedSelector,
  whiteListTableSelector,
} from '@/redux/slice/blockList.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  Banner,
  Card,
  Divider,
  IndexFilters,
  IndexFiltersMode,
  InlineGrid,
  LegacyCard,
  Modal,
  Tabs,
  Text,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';

import { images } from '@/asset';
import BannerTemplate from '@/components/BannerTemplate';
import BoldText from '@/components/BoldText';
import { config } from '@/config';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { bannerLimitRuleBlockAndRedirectSelector } from '@/redux/slice/banner.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { DeleteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import mixpanel from 'mixpanel-browser';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { BlackListFilter } from './components/Filters/Filter.blacklist';
import { WhiteListFilter } from './components/Filters/Filter.whitelist';
import TableBlackList from './components/TableBlackList';
import TableWhiteList from './components/TableWhiteList';
import { BlockListStyled } from './styled';

export interface IResponseQuoteSnap {
  data: boolean;
  errors: boolean;
  meta: {
    success: boolean;
    message: string;
  };
}

const selectedRuleConfig = [
  {
    value: Enum.ActionType.Block,
    label: 'Block',
  },
  {
    value: Enum.ActionType.Redirect,
    label: 'Redirect',
  },
  {
    value: '0',
    label: 'Whitelist',
  },
];

const tabsConfig = [
  {
    id: 'black-list',
    content: 'Blacklist',
    panelID: 'black-list',
    children: <TableBlackList />,
  },
  {
    id: 'white-list',
    panelID: 'white-list',
    content: 'Whitelist',
    children: <TableWhiteList />,
  },
];

const BlockList = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const tabSelected = useSelector(tabSelectedSelector);
  const tabSelectedModal = useSelector(tabSelectedModalSelector);
  const settings = useSelector(settingSelector);
  const isOpenGuide = useSelector(openGuideSelector);
  const bannerLimitRuleBlockAndRedirect = useSelector(bannerLimitRuleBlockAndRedirectSelector);

  const [trackAction] = apiCaller.useTrackActionMutation();
  const [deleteAllBlacklistItem, deleteAllBlacklistItemStatus] = apiCaller.useDeleteAllBlackListSettingMutation();
  const [deleteAllWhitelistItem, deleteAllWhitelistItemStatus] = apiCaller.useDeleteAllWhiteListSettingMutation();
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery({
    type: Enum.ActionType.Block,
    priority: Enum.ListType.BlackList,
    criteria: Enum.CriteriaType.IpAddress,
  });

  const { userPlanFree } = userPlans();

  const listRuleOptions = options.configRules.filter(
    (rule) =>
      rule.id !== CriteriaType.IpAddressStartWith &&
      rule.id !== CriteriaType.IpRanges &&
      rule.id !== CriteriaType.Province &&
      rule.id !== CriteriaType.Collection,
  );

  const [dataQuote, setDataQuote] = useState<IResponseQuoteSnap>();
  const [state, setState] = useState({
    isOpenModalDelete: false,
  });
  const [clearSelectedChildFn, setClearSelectedChildFn] = useState<(() => void) | null>(null);

  const getDisputeTemplate = () => {
    trackAction('get_dispute_template');
    const sendText =
      'I am using the Blockify app. I saw they introduced their partner Synctrack, I want to get Synctrack Dispute Management Ebook';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
    mixpanel?.track('Dispute_ebook');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://api.omegatheme.com/quote-snap/backend/server.php/api/shop-installed', {
          shop: config.shop,
          secret: process.env.REACT_APP_QUOTE_SNAP_ID,
        });
        setDataQuote(response.data);
      } catch (error) {
        console.error(error);
        setDataQuote({
          data: false,
          errors: false,
          meta: {
            success: true,
            message: '',
          },
        });
      }
    };

    fetchData();
  }, []);

  const handleSupportNewCustomer = () => {
    mixpanel?.track('Hide_price_try_for_free');
    window.open('https://apps.shopify.com/request-for-quote-by-omega?utm_campaign=cross-sell&utm_source=blockify-banner');
  };

  const handleSupportInstalledCustomer = () => {
    mixpanel?.track('Hide_price_set_up_app');
    window.open(`https://${config.shop}/admin/apps/request-for-quote-by-omega/quote-snap/frontend/dist/collect_quote/hide_price`);
  };

  const handleOpenModalDeleteAll = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: true,
    });
  }, [state]);

  const handleCloseModalDelete = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: false,
    });
  }, [state]);

  const onParentAction = useCallback((action: () => void) => {
    setClearSelectedChildFn(() => action);
  }, []);

  const handleDeleteAllBlacklist = useCallback(() => {
    deleteAllBlacklistItem({
      ids: undefined,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        clearSelectedChildFn?.();
        handleCloseModalDelete();
      }
    });
  }, [clearSelectedChildFn, deleteAllBlacklistItem, dispatch, handleCloseModalDelete]);

  const handleDeleteAllWhitelist = useCallback(() => {
    deleteAllWhitelistItem({
      ids: undefined,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        clearSelectedChildFn?.();
        handleCloseModalDelete();
      }
    });
  }, [clearSelectedChildFn, deleteAllWhitelistItem, dispatch, handleCloseModalDelete]);

  const handleModalClick = useCallback(
    (id: string) => {
      dispatch(slice.blockListSlice.actions.handleErrorRule([]));
      if (id === CriteriaType.ISP) {
        dispatch(blockListSlice.actions.handleInputIsp(''));
      }
      if (id === CriteriaType.Product) {
        dispatch(blockListSlice.actions.handleInputProduct(''));
      }
      navigate(PATH.BLOCK_PAGE);
      dispatch(
        slice.blockListSlice.actions.handleSetting({
          ...settings,
          isActive: true,
          id: undefined,
          linkRedirect: '',
          shortUrl: '',
          referUrl: '',
          shortReferUrl: '',
          note: '',
          collectionId: [],
          state: [],
          city: [],
          country: [],
          ipAddress: '',
          ispName: [],
          ispCode: [],
          productId: [],
          pageId: [],
          deviceType: '',
          osName: '',
          browserName: [],
          criteria: id,
          type: options.listTypeOptions[tabSelectedModal].id,
          priority:
            options.listTypeOptions[tabSelectedModal].id === ActionType.WhiteList ? ListType.WhiteList : ListType.BlackList,
        }),
      );
    },
    [dispatch, navigate, settings, tabSelectedModal],
  );

  // table
  const { mode, setMode } = useSetIndexFiltersMode();
  const blacklistTable = useSelector(blackListTableSelector);
  const whitelistTable = useSelector(whiteListTableSelector);
  const [inputSearch, setInputSearch] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchBlack = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleBlackListTable(value));
    }, 1000),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchWhite = useCallback(
    _debounce((value: IParamsApi.IGetSettingList) => {
      dispatch(blockListSlice.actions.handleWhiteListTable(value));
    }, 1000),
    [],
  );

  const handleInputChangeTable = useCallback(
    (search: string) => {
      setInputSearch(search);
      if (!tabSelected) {
        debounceSetSearchBlack({
          ...blacklistTable,
          page: search ? 1 : blacklistTable.page,
          search,
        });
      } else {
        debounceSetSearchWhite({
          ...whitelistTable,
          page: search ? 1 : blacklistTable.page,
          search,
        });
      }
    },
    [blacklistTable, debounceSetSearchBlack, debounceSetSearchWhite, tabSelected, whitelistTable],
  );

  const handleSelectListType = useCallback(
    (selected: number) => {
      dispatch(blockListSlice.actions.handleTabSelectedModal(selected));
      if (selected === 3) {
        mixpanel?.track('Fraud_banner_quote_impression');
      }
      const type = options.listTypeOptions[selected].id;
      dispatch(
        blockListSlice.actions.handleSetting({
          ...settings,
          type: type,
          priority: type === ActionType.WhiteList ? ListType.WhiteList : ListType.BlackList,
        }),
      );
      handleInputChangeTable('');
    },
    [dispatch, handleInputChangeTable, settings],
  );

  const handleClearAllFilter = useCallback(() => {
    if (!tabSelected) {
      dispatch(
        slice.blockListSlice.actions.handleBlackListTable({
          ...blacklistTable,
          perPage: '10',
          criteria: '',
          type: '',
        }),
      );
    } else {
      dispatch(
        slice.blockListSlice.actions.handleWhiteListTable({
          ...whitelistTable,
          perPage: '10',
          criteria: '',
        }),
      );
    }
  }, [blacklistTable, dispatch, tabSelected, whitelistTable]);

  return (
    <Layout
      layoutProps={{
        title: 'Block & Re-director',
      }}
    >
      <BlockListStyled isOpenGuide={isOpenGuide}>
        {bannerLimitRuleBlockAndRedirect && userPlanFree && (
          <div className="mb-16">
            <Banner
              title={
                (ruleBlockSummary?.data?.totalRulesActivated || 0) >= 6 ? 'You have reached the limitation of the Free plan.' : ''
              }
              tone={(ruleBlockSummary?.data?.totalRulesActivated || 0) >= 6 ? 'warning' : 'info'}
              action={{
                content: 'Increase limit',
                onAction: () => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Setting_rule_increase_limit');
                },
              }}
              onDismiss={() => {
                dispatch(slice.banner.actions.handleBannerLimitRuleBlockAndRedirect(false));
              }}
            >
              <RegularText>
                {(ruleBlockSummary?.data?.totalRulesActivated || 0) >= 6 ? (
                  <>
                    <b>{`${ruleBlockSummary?.data?.totalRulesActivated || 0}`}/6</b> active rules of the Free plan. Increase the
                    limit to add more rules.
                  </>
                ) : (
                  <>
                    <b>{`${ruleBlockSummary?.data?.totalRulesActivated || 0}`}/6</b> active rules of the Free plan. You nearly
                    reach the limitation of Free plan. Increase the limit to add more rules.
                  </>
                )}
              </RegularText>
            </Banner>
          </div>
        )}
        <div
          className="blocklist-wrapper"
          onClick={() => {
            dispatch(blockListSlice.actions.handleOpenGuide(false));
          }}
        />
        <div className="add-rule-wrapper">
          <Card padding="200">
            <Tabs tabs={options.listTypeOptions} selected={tabSelectedModal} onSelect={handleSelectListType}>
              <Divider />
              {tabSelectedModal !== 3 ? (
                <div style={{ padding: isMobile ? '0.5rem' : '1rem' }}>
                  <InlineGrid columns={{ xs: 4, lg: 7 }} gap={{ xs: '100', md: '200' }}>
                    {listRuleOptions.map((item, index) => {
                      return (
                        <Card key={index} padding={'0'}>
                          <div key={index} className="add-rule-container" onClick={() => handleModalClick(item.id)}>
                            <img className="add-rule-img" src={item.src} alt={item.description} />
                            <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                              {`${selectedRuleConfig.find((item) => item.value === options.listTypeOptions[tabSelectedModal].id)
                                ?.label
                                } ${item.description}`}
                            </Text>
                          </div>
                        </Card>
                      );
                    })}
                  </InlineGrid>
                </div>
              ) : (
                <div className="pd-8">
                  <BannerTemplate src={images.quoteSnap}>
                    <BoldText>Hide price from selected location</BoldText>
                    <div className="mt-8">
                      <RegularText>
                        Easy hide price from multi selected Locations with just one click. Discover now on Omega Quote Snap - free
                        plan available.
                      </RegularText>
                    </div>
                    <div
                      className="mt-16"
                      onClick={dataQuote && dataQuote.data ? handleSupportInstalledCustomer : handleSupportNewCustomer}
                    >
                      <CustomButton>{dataQuote && dataQuote.data ? 'Set up now' : 'Try for free'}</CustomButton>
                    </div>
                  </BannerTemplate>
                </div>
              )}
            </Tabs>
          </Card>
        </div>

        <div className="card-table">
          {mode === IndexFiltersMode.Filtering ? null : (
            <div className="btn-delete">
              <Tooltip content="Delete all">
                <CustomButton icon={DeleteMinor} onClick={handleOpenModalDeleteAll} />
              </Tooltip>
            </div>
          )}

          <LegacyCard>
            <IndexFilters
              queryValue={inputSearch}
              queryPlaceholder="Searching by IP, country, state/province, internet provider"
              onQueryChange={handleInputChangeTable}
              onQueryClear={() => {
                handleInputChangeTable('');
              }}
              tabs={tabsConfig}
              selected={tabSelected}
              onSelect={(tabIndex) => {
                dispatch(slice.blockListSlice.actions.handleTabSelected(tabIndex));
              }}
              canCreateNewView={false}
              filters={!tabSelected ? BlackListFilter().filters : WhiteListFilter().filters}
              appliedFilters={!tabSelected ? BlackListFilter().appliedFilters : WhiteListFilter().appliedFilters}
              onClearAll={handleClearAllFilter}
              cancelAction={{ onAction: () => { } }}
              mode={mode}
              setMode={setMode}
            />
            {!tabSelected ? (
              <TableBlackList onParentAction={onParentAction} />
            ) : (
              <TableWhiteList onParentAction={onParentAction} />
            )}
          </LegacyCard>
        </div>

        <div className="mt-16">
          <BannerTemplate src={images.disputeImg}>
            <BoldText>We are giving you guidelines on how to win a Dispute</BoldText>
            <div className="mt-8">
              <RegularText>
                Disputes and chargebacks can potentially drain your business. Understanding your pain, we have launched an eBook
                that helps you communicate effectively with customers or PayPal to win when a dispute has been raised.
              </RegularText>
            </div>
            <div className="mt-16">
              <CustomButton variant="primary" onClick={getDisputeTemplate}>
                Contact to get free Ebook
              </CustomButton>
            </div>
          </BannerTemplate>
        </div>

        <Modal
          open={state.isOpenModalDelete}
          onClose={handleCloseModalDelete}
          title="Delete all"
          primaryAction={{
            destructive: true,
            content: 'Delete',
            onAction: tabSelected === Enum.SelectedTab.Blacklist ? handleDeleteAllBlacklist : handleDeleteAllWhitelist,
            loading: deleteAllBlacklistItemStatus.isLoading || deleteAllWhitelistItemStatus.isLoading,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCloseModalDelete,
            },
          ]}
        >
          <Modal.Section>
            <RegularText>If you deleted all rules, you won't be able to revert it</RegularText>
          </Modal.Section>
        </Modal>
      </BlockListStyled>
    </Layout>
  );
};
export default memo(BlockList);
