import styled from 'styled-components';

export const CustomSettingToggleStyled = styled.div`
  .Polaris-Badge {
    margin-left: 4px;
  }
  .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    max-height: 60px;
  }
  .toggle-settings-title {
    display: flex;
    align-items: center;
    line-height: 1.75rem;
    .tone-yellow {
      .Polaris-Badge {
        background-color: #FFEA8A;
      }
    }
    .tone-orange {
      .Polaris-Badge {
        background-color: #FFC694;
      }
    }
    .Polaris-Text--root {
      display: flex;
    }
    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
      width: 2rem;
    }
  }
  .Polaris-SkeletonDisplayText__DisplayText {
    width: 4.5rem;
  }
  .ml-4 {
    margin-left: 0.25rem;
  }
`;
