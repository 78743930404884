import BoldText from '@/components/BoldText';
import CustomLayout from '@/components/layout';
import { BREAKPOINT } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { selectedSelector } from '@/redux/slice/settings.slice';
import { Banner, Button, Icon, InlineGrid, OptionList } from '@shopify/polaris';
import { CircleDisabledMajor, ComposeMajor, SecureMajor, SettingsMinor, ViewMajor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BlockerAndRedirector from './components/BlockerAndRedirector';
import ContentProtection from './components/contentProtection';
import CustomTemplate from './components/customTemplate';
import GeneralSettings from './components/GeneralSettings';
import { SettingsStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import userPlans from '@/hooks/userPlans';
import { bannerLimitRuleSettingSelector } from '@/redux/slice/banner.slice';
import { Enum, PATH } from '@/constants';
import mixpanel from 'mixpanel-browser';
import RegularText from '@/components/RegularText';

const configItems = [
  {
    value: 'general',
    content: <GeneralSettings />,
  },
  {
    value: 'blocker',
    content: <BlockerAndRedirector />,
  },
  {
    value: 'content',
    content: <ContentProtection />,
  },
  {
    value: 'template',
    content: <CustomTemplate />,
  },
];
const options = [
  {
    value: 'general',
    label: (
      <div className="settings-title">
        <Icon source={SettingsMinor} tone="base" />
        <BoldText>General settings</BoldText>
      </div>
    ),
  },
  {
    value: 'blocker',
    label: (
      <div className="settings-title">
        <Icon source={CircleDisabledMajor} tone="base" />
        <BoldText>Bot killer</BoldText>
      </div>
    ),
  },
  {
    value: 'content',
    label: (
      <div className="settings-title">
        <Icon source={SecureMajor} tone="base" />
        <BoldText>Content protection</BoldText>
      </div>
    ),
  },
  {
    value: 'template',
    label: (
      <div className="settings-title">
        <Icon source={ComposeMajor} tone="base" />
        <BoldText>Blocking template</BoldText>
      </div>
    ),
  },
];
const SecondaryAction = (): JSX.Element => {
  const dataSettings = useSelector(dataSettingsSelector);
  const handleOpenPreview = () => {
    window.open(dataSettings?.settings.testBlockUrl);
  };
  return (
    <Button onClick={handleOpenPreview} icon={ViewMajor}>
      Preview
    </Button>
  );
};

const Settings = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userPlanFree } = userPlans();
  const bannerLimitRule = useSelector(bannerLimitRuleSettingSelector);
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery({
    type: Enum.ActionType.Block,
    priority: Enum.ListType.BlackList,
    criteria: Enum.CriteriaType.IpAddress,
  });
  const selected = useSelector(selectedSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const [fetchGA] = apiCaller.useFetchGAMutation();
  const handleSelectTab = (selectedTabValue: string) => {
    if (selectedTabValue === 'general') {
      fetchGA({
        eventName: 'activate_impression',
      });
    }
  };

  return (
    <SettingsStyled>
      <CustomLayout
        layoutProps={{
          title: 'Settings',
          secondaryActions: selected[0] === 'custom' ? <SecondaryAction /> : undefined,
        }}
      >
        {bannerLimitRule && userPlanFree && (
          <div className="mb-16">
            <Banner
              title="You have reached the limitation of the Free plan."
              tone="warning"
              action={{
                content: 'Increase limit',
                onAction: () => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Settings_increase_limit');
                },
              }}
              onDismiss={() => {
                dispatch(slice.banner.actions.handleBannerLimitRuleSetting(false));
              }}
            >
              <RegularText>
                <b>{`${ruleBlockSummary?.data?.totalRulesActivated || 0}`}/6</b> active rules of the Free plan. Increase the limit
                to add more rules.
              </RegularText>
            </Banner>
          </div>
        )}
        <InlineGrid columns={isMobile ? 1 : ['oneThird', 'twoThirds']} gap={'400'}>
          <OptionList
            title=""
            onChange={(value: Array<string>) => {
              dispatch(slice.settingsSlice.actions.handleSelected(value));
              handleSelectTab(value[0]);
            }}
            options={options}
            selected={selected}
          />
          {configItems.find((item) => item.value === selected[0])?.content}
        </InlineGrid>
      </CustomLayout>
    </SettingsStyled>
  );
};

export default memo(Settings);
