import BannerGrantPermission from '@/components/BannerGrantPermission';
import BoldText from '@/components/BoldText';
import ButtonSupport from '@/components/ButtonSupport';
import ProcessOldOrders from '@/components/ProcessOldOrders';
import RegularText from '@/components/RegularText';
import CustomDatePicker from '@/components/datePicker';
import Layout from '@/components/layout';
import SettingToggle from '@/components/settingToggle';
import { config } from '@/config';
import { Enum } from '@/constants';
import { ScopeGroups, UserPlan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, dateToTimeStamp, disablePlan, handleToastMutation } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { fraudOrderBannerSelector } from '@/redux/slice/banner.slice';
import { dataSettingsSelector, loadingSelector } from '@/redux/slice/dataSettings.slice';
import fraudOrdersSlice, { fraudOrdersTableSelector, titleBtnDatePickerSelector } from '@/redux/slice/fraudOrders';
import toastSlice from '@/redux/slice/toast.slice';
import { Badge, Banner, Button, Card, Icon, Link } from '@shopify/polaris';
import { OrdersMajor, RefreshMajor } from '@shopify/polaris-icons';
import mixpanel from 'mixpanel-browser';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Analytics from './Components/Analytics';
import Table from './Components/Table';
import { FraudOrdersStyled } from './styled';

const PrimaryAction = (): JSX.Element => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const dataSettings = useSelector(dataSettingsSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const fraudOrdersTable = useSelector(fraudOrdersTableSelector);
  const getListOrder = apiCaller.useGetListOrderQuery({
    endDate: dateToTimeStamp(fraudOrdersTable.endDate),
    startDate: dateToTimeStamp(fraudOrdersTable.startDate),
    ip: fraudOrdersTable.search,
    isVpn: fraudOrdersTable.vpn,
    page: fraudOrdersTable.page,
    perPage: Number(fraudOrdersTable.perPage),
    sortBy: fraudOrdersTable.sortBy,
    sortDirection: fraudOrdersTable.sort.toUpperCase(),
  });
  const getOrderSummary = apiCaller.useFraudOrdersSummaryQuery({
    identifierId: config.shop,
    startDate: dateToTimeStamp(fraudOrdersTable.startDate),
    endDate: dateToTimeStamp(fraudOrdersTable.endDate),
  });
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    dispatch(
      fraudOrdersSlice.actions.handleFraudOrdersTable({
        ...fraudOrdersTable,
        startDate,
        endDate,
      }),
    );
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(fraudOrdersSlice.actions.handleTitleBtnDatePicker(title));
  };
  const handleRefresh = () => {
    Promise.all([getListOrder.refetch(), getOrderSummary.refetch]);
  };
  return (
    <div className="d-flex mr-8">
      <Button loading={getListOrder.isFetching || getOrderSummary.isLoading} onClick={handleRefresh} icon={RefreshMajor}>
        Refresh
      </Button>
      <div className="ml-8">
        <CustomDatePicker
          titleButton={titleBtn}
          setTitleButton={onSaveTitleBtnDatePicker}
          startDate={fraudOrdersTable.startDate}
          endDate={fraudOrdersTable.endDate}
          onSave={onSaveDatePicker}
          isShowSelectedTime={true}
          conditions={false}
        />
      </div>
      {dataSettings?.settings.user.orderScope ? (
        <div className="ml-8">
          <Button
            onClick={() => {
              setIsOpenModal(true);
            }}
            icon={<Icon source={OrdersMajor} tone="base" />}
          >
            Analyze old orders
          </Button>
          <ProcessOldOrders isOpen={isOpenModal} handleIsOpen={(value) => setIsOpenModal(value)} />
        </div>
      ) : null}
    </div>
  );
};

const FraudOrders = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  const navigate = useNavigate();
  const dataSettings = useSelector(dataSettingsSelector);
  const fraudOrderBanner = useSelector(fraudOrderBannerSelector);
  const { userPlanFree, userPlanPremium, shopifyPlanPlus } = userPlans();
  const [rangeValue, setRangeValue] = useState(Number(dataSettings?.settings.orderRiskScore));
  const [activeFraudOrderScores, activeFraudOrderScoresStatus] = apiCaller.useActiveFraudOrderScoresMutation();
  const ruleBlockSummary = apiCaller.useRulesSummaryQuery({
    type: Enum.ActionType.Block,
    priority: Enum.ListType.BlackList,
    criteria: Enum.CriteriaType.IpAddress,
  });
  const countNumberOfRule = () => {
    return ruleBlockSummary.data ? ruleBlockSummary.data.totalRulesActivated : 0;
  };

  const handleAutoBlockFraudOrders = () => {
    activeFraudOrderScores({ autoBlockFraudOrders: !dataSettings?.settings.autoBlockFraudOrders, riskScore: rangeValue }).then(
      (res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      },
    );
  };

  useEffect(() => {
    if (dataSettings && dataSettings.settings.orderRiskScore) {
      setRangeValue(Number(dataSettings.settings.orderRiskScore));
    }
  }, [dataSettings]);

  return (
    <Layout layoutProps={{ title: 'Fraud orders analytics', fullWidth: true, primaryAction: <ButtonSupport /> }}>
      <FraudOrdersStyled>
        <BannerGrantPermission
          title="Important step to use this feature"
          isVisible={!dataSettings?.settings.user.orderScope}
          enum={ScopeGroups.processOrders}
          children="To use this feature, we need permission to view your store orders data."
          variant="primary"
        />
        {fraudOrderBanner && userPlanFree && (
          <div className="mb-16">
            <Banner
              tone="info"
              action={{
                content: 'Increase limit',
                onAction: () => {
                  navigate(PATH.PRICING_PLAN);
                  mixpanel?.track('Fraud_order_increase_limit');
                },
              }}
              onDismiss={() => {
                dispatch(slice.banner.actions.handleFraudOrderBanner(false));
              }}
            >
              <RegularText>
                You have reached limitation of the <b>Free plan ({Math.max(countNumberOfRule(), 0)}/6 rules)</b>. Upgrade to
                unlock unlimited access to Blockify.
              </RegularText>
            </Banner>
          </div>
        )}
        <div className="mb-16">
          <PrimaryAction />
        </div>
        <SettingToggle
          minActivePlan={shopifyPlanPlus && !userPlanPremium ? Enum.UserPlan.SHOPIFYPLUS : Enum.UserPlan.ENTERPRISE}
          title="Auto-block visitors placing fraud orders"
          settingToggleProps={{
            enabled: !disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) && dataSettings?.settings.autoBlockFraudOrders,
            action: { onAction: handleAutoBlockFraudOrders, loading: activeFraudOrderScoresStatus.isLoading || isLoading },
          }}
          mixpanelKey="Fraud_order_button"
          contentTooltip={!dataSettings?.settings.user.orderScope ? 'Please grant permission to turn on this feature' : undefined}
        >
          <RegularText>
            Turn on this feature so that when there is an order that Shopify detects as high risk of fraud, we will automatically
            add the IPs that created this high risk order to the blocked list.{' '}
            <Link target="_blank" url="https://docs.ipblocker.io/getting-started/fraud-orders">
              Learn about fraud orders.
            </Link>
            {/* {dataSettings?.settings.autoBlockFraudOrders && userPlanEnterprise ? (
              <div className="mt-16 mb-16 range">
                <RangeSlider
                  output
                  label={<RegularText>Fraud order score starts at</RegularText>}
                  value={rangeValue}
                  suffix={
                    <p
                      style={{
                        minWidth: '24px',
                        textAlign: 'right',
                      }}
                    >
                      {rangeValue}
                    </p>
                  }
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={handleRangeSliderChange}
                />
                <div className="risk-score">
                  ({rangeValue < 0.4 ? 'Low risk' : rangeValue < 0.7 ? 'Medium risk' : 'High risk'})
                </div>
              </div>
            ) : null} */}
          </RegularText>
        </SettingToggle>

        <div className="mt-16">
          <Card>
            <div className="checkout-rules-banner-heading">
              <BoldText>
                Customize your checkout page with Blockify: Checkout Rules
                <span className="ml-8">
                  <Badge tone="success">Free</Badge>
                </span>
              </BoldText>
              <Button variant="primary" url="https://apps.shopify.com/blockify-checkout-rules" target="_blank">
                Install app
              </Button>
            </div>

            <div className="mt-8">
              <RegularText>
                Validate checkout fields on the checkout page. Also, you can personalize checkout by customizing payment and
                shipping methods.
              </RegularText>
            </div>
          </Card>
        </div>

        <div className="mt-16">
          <div>
            <Analytics />
          </div>
        </div>

        <div className="mt-16">
          <div>
            <Table />
          </div>
        </div>
      </FraudOrdersStyled>
    </Layout>
  );
};

export default memo(FraudOrders);
