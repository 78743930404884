import { colors } from '@/constants/colors';
import styled from 'styled-components';

export const WelcomeStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  > .Polaris-Box {
    max-width: 950px;
    width: 950px;
  }
  .welcome-container {
    .welcome-process-container {
      height: 0.75rem;
      width: 80%;
      background-color: ${colors.gray_background};
      border-radius: 0.375rem;
      overflow: hidden;
      margin: 0 auto;
    }
    .welcome-process {
      background-color: #1a1a1a;
      height: 100%;
    }
    ul {
      width: 80%;
      padding: 0;
      display: flex;
      justify-content: center;
      margin: 16px auto 32px;
      li {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .step-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        }
        .Polaris-Text--root {
          text-align: center;
          color: #ababab;
        }
        .Polaris-Text--root:hover {
          text-decoration: underline;
        }
      }
      .active {
        .Polaris-Text--root {
          color: #303641;
        }
      }
      .completed {
        .Polaris-Text--root {
          color: #000000;
        }
      }
    }
  }
  .step-two {
    img {
      margin-top: 1rem;
      width: 910px;
    }
  }

  .Polaris-Text--root {
    text-align: center;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .button-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-center {
    display: flex;
    justify-content: center;
    margin: 16px auto;
  }
  .step-three-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    .banner-warning {
      margin-top: 16px;
      padding: 0.5rem;
      width: fit-content;
      background: rgba(255, 241, 227, 1);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .step-three-warning-banner {
    max-width: 500px;
  }
`;
