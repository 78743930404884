/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import RegularText from '@/components/RegularText';
import { Text } from '@shopify/polaris';
import { memo } from 'react';
const StepFour = () => {
  return (
    <div className="tab-pane">
      <div className="welcome-title">
        <Text as="h3" variant="headingXl">
          Hurray! You are all set!
        </Text>
      </div>
      <div className="panel-body">
        <div className="image-center">
          <img src={images.insurance} alt="Responsive image" />
        </div>
        <p className="welcome-description" style={{ fontSize: '16px', textAlign: 'center' }}>
          <RegularText>
            Say goodbye to potential threats and maintain a safer online selling experience with Blockify!
          </RegularText>
        </p>
      </div>
    </div>
  );
};

export default memo(StepFour);
