import { images } from '@/asset';
import { CheckoutRulesBannerStyled } from './styled';
import { Icon } from '@shopify/polaris';
import { CancelMajor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import bannerSlice, { checkoutRulesBannerSelector } from '@/redux/slice/banner.slice';
import { CSSProperties } from 'react';
const CheckoutRulesBanner = (style: CSSProperties | undefined) => {
  const isVisible = useSelector(checkoutRulesBannerSelector);
  const dispatch = useDispatch();
  return isVisible ? (
    <CheckoutRulesBannerStyled
      style={style}
      onClick={() => window.open('https://apps.shopify.com/blockify-checkout-rules', '_blank')}
    >
      <img src={images.checkoutRulesBanner} alt="checkout-rules-banner" />
      <div
        className="btn-hide-banner"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(bannerSlice.actions.handleCheckoutRulesBanner(false));
        }}
      >
        <Icon source={CancelMajor} tone="base" />
      </div>
    </CheckoutRulesBannerStyled>
  ) : null;
};

export default CheckoutRulesBanner;
