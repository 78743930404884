import React, { memo } from 'react';
import iconCheck from '@/asset/images/icon-check-plan.png';
import userPlans from '@/hooks/userPlans';
import { BlockStack } from '@shopify/polaris';

type PlanValue = string | boolean | JSX.Element;
type Features = Record<string, Record<string, Record<string, PlanValue>>>;

const renderImage = <img width={20} height={20} src={iconCheck} alt="iconCheck" />;

const RenderContent = ({ content }: { content: string }) => (
  <BlockStack gap="050" inlineAlign="center">
    {renderImage}
    <p>{content}</p>
  </BlockStack>
);

const featuresRegular: Features = {
  Block: {
    IP: { free: <RenderContent content="Address, Start with" />, premium: renderImage, enterprise: renderImage },
    Location: { free: <RenderContent content="Country" />, premium: renderImage, enterprise: renderImage },
    'Products/Collections': { free: false, premium: renderImage, enterprise: renderImage },
    'User agent': { free: false, premium: renderImage, enterprise: renderImage },
    ISP: { free: false, premium: renderImage, enterprise: renderImage },
    'Referrer URL': { free: false, premium: renderImage, enterprise: renderImage },
  },
  Redirect: {
    IP: { free: <RenderContent content="Address, Start with" />, premium: renderImage, enterprise: renderImage },
    Location: { free: <RenderContent content="Country" />, premium: renderImage, enterprise: renderImage },
    'Products/Collections': { free: false, premium: renderImage, enterprise: renderImage },
    'User agent': { free: false, premium: renderImage, enterprise: renderImage },
    ISP: { free: false, premium: renderImage, enterprise: renderImage },
    'Referrer URL': { free: false, premium: renderImage, enterprise: renderImage },
  },
  Whitelist: {
    IP: { free: <RenderContent content="Address, Start with" />, premium: renderImage, enterprise: renderImage },
    Location: { free: <RenderContent content="Country" />, premium: renderImage, enterprise: renderImage },
    'Products/Collections': { free: false, premium: renderImage, enterprise: renderImage },
    'User agent': { free: false, premium: renderImage, enterprise: renderImage },
    ISP: { free: false, premium: renderImage, enterprise: renderImage },
    'Referrer URL': { free: false, premium: renderImage, enterprise: renderImage },
  },
  'Fraud order': {
    'Fraud order analytics': { free: false, premium: renderImage, enterprise: renderImage },
    'Auto-block visitors place fraud order': { free: false, premium: false, enterprise: renderImage },
  },
  'Block checkout': {
    'Auto-block order based on subtotal conditions': { free: false, premium: false, enterprise: renderImage },
    'Block email address': { free: false, premium: false, enterprise: renderImage },
    'Block customer name': { free: false, premium: false, enterprise: renderImage },
    'Block address & zipcode': { free: false, premium: false, enterprise: renderImage },
    'Block phone number': { free: false, premium: false, enterprise: renderImage },
  },
  // 'Hide payment': {
  //   'Hide payment method': { free: renderImage, premium: renderImage, enterprise: renderImage },
  //   'Hide express checkout': { free: renderImage, premium: renderImage, enterprise: renderImage },
  // },
  'Auto-block': {
    'Auto-block visitors uses Proxy/VPN': { free: false, premium: renderImage, enterprise: renderImage },
    'Auto block Tor': { free: false, premium: false, enterprise: renderImage },
    'Auto detect and block visitor change IP address': { free: false, premium: renderImage, enterprise: renderImage },
  },
  'Content protection': {
    'Prevent copy & paste content': { free: false, premium: renderImage, enterprise: renderImage },
    'Prevent right click & inspect': { free: false, premium: false, enterprise: renderImage },
    'Prevent shortcut': { free: false, premium: false, enterprise: renderImage },
  },
  Others: {
    Support: { free: '24/7', premium: 'VIP priority', enterprise: 'VIP priority' },
    'Styling & CSS blocking template': { free: false, premium: renderImage, enterprise: renderImage },
    'Expert review set up': { free: false, premium: false, enterprise: renderImage },
  },
};
const featuresShopifyPlus: Features = {
  Block: {
    IP: { free: <RenderContent content="Address, Start with" />, 'shopify plus': renderImage },
    Location: { free: <RenderContent content="Country" />, 'shopify plus': renderImage },
    'Products/Collections': { free: false, 'shopify plus': renderImage },
    'User agent': { free: false, 'shopify plus': renderImage },
    ISP: { free: false, 'shopify plus': renderImage },
    'Referrer URL': { free: false, 'shopify plus': renderImage },
  },
  Redirect: {
    IP: { free: <RenderContent content="Address, Start with" />, 'shopify plus': renderImage },
    Location: { free: <RenderContent content="Country" />, 'shopify plus': renderImage },
    'Products/Collections': { free: false, 'shopify plus': renderImage },
    'User agent': { free: false, 'shopify plus': renderImage },
    ISP: { free: false, 'shopify plus': renderImage },
    'Referrer URL': { free: false, 'shopify plus': renderImage },
  },
  Whitelist: {
    IP: { free: <RenderContent content="Address, Start with" />, 'shopify plus': renderImage },
    Location: { free: <RenderContent content="Country" />, 'shopify plus': renderImage },
    'Products/Collections': { free: false, 'shopify plus': renderImage },
    'User agent': { free: false, 'shopify plus': renderImage },
    ISP: { free: false, 'shopify plus': renderImage },
    'Referrer URL': { free: false, 'shopify plus': renderImage },
  },
  'Fraud order': {
    'Fraud order analytics': { free: false, 'shopify plus': renderImage },
    'Auto-block visitors place fraud order': { free: false, 'shopify plus': renderImage },
  },
  'Block checkout': {
    'Auto-block order based on subtotal conditions': { free: false, 'shopify plus': renderImage },
    'Block email address': { free: false, 'shopify plus': renderImage },
    'Block customer name': { free: false, 'shopify plus': renderImage },
    'Block address & zipcode': { free: false, 'shopify plus': renderImage },
    'Block phone number': { free: false, 'shopify plus': renderImage },
  },
  // 'Hide payment': {
  //   'Hide payment method': { free: renderImage, 'shopify plus': renderImage },
  //   'Hide express checkout': { free: renderImage, 'shopify plus': renderImage },
  // },
  'Auto-block': {
    'Auto-block visitors uses Proxy/VPN': { free: false, 'shopify plus': renderImage },
    'Auto block Tor': { free: false, 'shopify plus': renderImage },
    'Auto detect and block visitor change IP address': { free: false, 'shopify plus': renderImage },
  },
  'Content protection': {
    'Prevent copy & paste content': { free: false, 'shopify plus': renderImage },
    'Prevent right click & inspect': { free: false, 'shopify plus': renderImage },
    'Prevent shortcut': { free: false, 'shopify plus': renderImage },
  },
  Others: {
    Support: { free: '24/7', 'shopify plus': 'VIP priority' },
    'Styling & CSS blocking template': { free: false, 'shopify plus': renderImage },
    'Expert review set up': { free: false, 'shopify plus': renderImage },
  },
};

const plansRegular = ['FREE', 'PREMIUM', 'ENTERPRISE'];
const plansShopifyPlus = ['FREE', 'SHOPIFY PLUS'];

const ComparePlan: React.FC = () => {
  const { shopifyPlanPlus } = userPlans();

  const features = shopifyPlanPlus ? featuresShopifyPlus : featuresRegular;
  const plans = shopifyPlanPlus ? plansShopifyPlus : plansRegular;

  return (
    <div className="compare-plan-container">
      <table className="compare-table">
        <thead>
          <tr className="feature-group">
            <th className="sticky-col"></th>
            {plans.map((plan) => (
              <th className="check sticky-col" key={plan}>
                {plan}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(features).map(([group, groupFeatures]) => (
            <React.Fragment key={group}>
              <tr className="feature-group">
                <td className="sticky-col">{group}</td>
                {plans.map((plan) => (
                  <td key={plan}></td>
                ))}
              </tr>
              {Object.entries(groupFeatures).map(([feature, availablePlans]) => (
                <tr key={feature}>
                  <td className="sticky-col">{feature}</td>
                  {plans.map((plan) => (
                    <td key={plan} className="check">
                      <span>{availablePlans[plan.toLowerCase()]}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(ComparePlan);
