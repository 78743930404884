import React from 'react';
import { SpecialOfferStyled } from './styled';
import { Button, Card, EmptyState } from '@shopify/polaris';
import { images } from '@/asset';
import { CancelMajor } from '@shopify/polaris-icons';

interface SpecialOfferProps {
  image?: string;
  heading: string;
  description: string;
  labelPrimaryBtn: string;
  onClickPrimaryBtn: () => void;
  labelSecondaryBtn: string;
  onClickSecondaryBtn: () => void;
}

const SpecialOffer: React.FC<SpecialOfferProps> = ({
  image,
  heading,
  labelPrimaryBtn,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  labelSecondaryBtn,
  description,
}) => {
  return (
    <SpecialOfferStyled className="special_offer">
      <div className="cancel-icon">
        <Button size="large" icon={CancelMajor} tone="critical" variant="tertiary" onClick={onClickSecondaryBtn} />
      </div>
      <Card>
        <EmptyState
          heading={heading}
          action={{ content: labelPrimaryBtn, onAction: onClickPrimaryBtn }}
          secondaryAction={{
            content: labelSecondaryBtn,
            onAction: onClickSecondaryBtn,
          }}
          image={image || images.balloon}
        >
          <p className="description">{description}</p>
        </EmptyState>
      </Card>
    </SpecialOfferStyled>
  );
};

export default SpecialOffer;
