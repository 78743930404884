import { useDispatch, useSelector } from 'react-redux';
import useErrorRule from '../../hooks/useErrorRule';
import blockCheckoutAccessSlice, { settingSelector } from '@/redux/slice/blockCheckoutAccess';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import { capitalizeFirstLetter } from '@/helpers';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import { CriteriaType } from '@/constants/enum';
import { Select } from '@shopify/polaris';
import { memo } from 'react';

const Agents = () => {
  const dispatch = useDispatch();
  const useError = useErrorRule();
  const blockRuleSelected = useSelector(settingSelector);
  const handleCountry = useCountry({
    countryCode: blockRuleSelected.country ? blockRuleSelected.country[0] : undefined,
    stateName: blockRuleSelected.state ? blockRuleSelected.state[0] : undefined,
  });
  const dataUserAgent = apiCaller.useGetUserAgentQuery();
  const deviceOptions = dataUserAgent.data?.userAgents.devices.map((item) => ({
    value: item,
    label: capitalizeFirstLetter(item),
  }));

  const handleChange = (key: keyof typeof blockRuleSelected) => (value: string | string[]) => {
    dispatch(
      blockCheckoutAccessSlice.actions.handleSetting({
        ...blockRuleSelected,
        [key]: value,
      }),
    );
  };
  return (
    <div>
      <AutoCompleteWithTag
        requiredIndicator
        options={[...handleCountry.otherCountry, ...handleCountry.listCountry]}
        label="Select a country"
        selectedOptions={blockRuleSelected?.country || []}
        placeholder="-Country-"
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          } else if (useError.getErrorRuleByCriteria(CriteriaType.Country)?.error) {
            useError.removeErrorRule(CriteriaType.Country);
          }
          if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('country')(value);
        }}
        allowMultiple={false}
        error={useError.getErrorRuleByCriteria(CriteriaType.Country)?.error}
        onBlur={() => {
          if (blockRuleSelected.country?.length === 0) {
            useError.handleErrorRule('Country must be added', CriteriaType.Country);
          }
        }}
      />
      <div className="mt-8" />

      <AutoCompleteWithTag
        requiredIndicator
        helpText={!blockRuleSelected?.id ? 'You can select multiple browsers at the same time.' : undefined}
        allowMultiple={!blockRuleSelected?.id}
        label="Select browsers"
        options={dataUserAgent.data?.userAgents.browsers.map((item) => ({ value: item, label: item })) || []}
        selectedOptions={blockRuleSelected?.browserName || []}
        setSelectedOptions={(value) => {
          if (value.length === 0) {
            useError.handleErrorRule('Browser must be added');
          } else if (useError.errorSelected?.error) {
            useError.removeErrorRule();
          }
          handleChange('browserName')(value);
        }}
        placeholder="-Browsers-"
        error={useError.errorSelected?.error}
        onBlur={() => {
          if (blockRuleSelected.browserName?.length === 0) {
            useError.handleErrorRule('Browser must be added');
          }
        }}
      />
      <div className="mt-8" />

      <Select
        label="Select device (option)"
        options={deviceOptions}
        onChange={handleChange('deviceType')}
        value={blockRuleSelected.deviceType}
        placeholder="-Device-"
      />
      <div className="mt-8" />

      <Select
        label="Select OS (option)"
        options={dataUserAgent.data?.userAgents.os}
        onChange={handleChange('osName')}
        value={blockRuleSelected.osName}
        placeholder="-OS-"
      />
    </div>
  );
};

export default memo(Agents);
