import React, { useCallback } from 'react';
import Slider from 'react-slick';
import { rightRecommendApp } from './configRecommend';
import { Button, Card } from '@shopify/polaris';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IGA4 } from '@/types/components';

const RightRecommendApps = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };
  const handleClick = useCallback(
    (item: IGA4) => () => {
      window.open(item.href, '_blank', 'noopener,noreferrer');
    },
    [],
  );
  return (
    <Slider {...settings}>
      {rightRecommendApp.map((item) => {
        return (
          <Card padding={'400'} key={item.title}>
            <div className="recommend-app-container">
              <div className="recommend-app-content">
                <img src={item.logo} alt={item.title} style={{ maxHeight: '59px' }} />
                <div>
                  <BoldText>{item.title}</BoldText>
                </div>
                <RegularText>{item.description}</RegularText>
              </div>
              <Button onClick={handleClick(item)}>{item.label}</Button>
            </div>
          </Card>
        );
      })}
    </Slider>
  );
};

export default RightRecommendApps;
