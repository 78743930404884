import EmptyState from '@/components/EmptyState';
import RegularText from '@/components/RegularText';
import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { Enum, PATH } from '@/constants';
import { urlRickScore } from '@/constants/link';
import { dateToTimeStamp, findIPRange, formatDate } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import blockedVisitorSlice, { blockedDetailSelector, blockHistorySelector } from '@/redux/slice/blockedVisitor.slice';
import { allAccessSelector, perPageSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Badge, BadgeProps, Icon, IndexTable, Link, Pagination, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const badgeRiskConfig: Array<{ score: Array<number>; tone: BadgeProps['tone']; tooltip: string }> = [
  {
    score: [-1, Enum.RiskLevel.Safe],
    tone: 'success',
    tooltip: 'Low risk IP',
  },
  {
    score: [Enum.RiskLevel.Safe, Enum.RiskLevel.Risky],
    tone: 'warning',
    tooltip: 'High risk IP',
  },
  {
    score: [Enum.RiskLevel.Risky, Enum.RiskLevel.Dangerous],
    tone: 'critical',
    tooltip: 'Dangerous IP',
  },
];

const TableBlocked = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCountry = useCountry();
  const blockHistoryTable = useSelector(blockHistorySelector);
  const blockedDetail = useSelector(blockedDetailSelector);
  const allAccess = useSelector(allAccessSelector);
  const perPage = useSelector(perPageSelector);

  const { userPlanFree } = userPlans();

  const { data, isFetching } = apiCaller.useFetchVisitorBlockListQuery({
    ...blockHistoryTable,
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    perPage,
  });

  const resourceName = {
    singular: 'blocked',
    plural: 'blocked',
  };

  const items = useMemo(() => {
    return data?.listIp.map((blockedItem) => {
      const handleCopyIPAdress = () => {
        navigator.clipboard.writeText(blockedItem.ip || '').then(() => {
          dispatch(
            slice.toastSlice.actions.handleToast({
              isOpen: true,
              content: 'Copied',
              error: false,
            }),
          );
        });
      };
      const riskScore = blockedItem.risk;
      const isVpn: boolean = blockedItem.isVpn;

      let badgeRisk;
      if (riskScore === null) {
        badgeRisk = undefined;
      } else {
        badgeRisk = badgeRiskConfig.find((item) => {
          return item.score[0] < riskScore && riskScore <= item.score[1];
        });
      }
      return {
        id: blockedItem.id,
        ipBlocked: (
          <span className="d-flex address-container">
            <RegularText>
              {blockedItem.ip}
              <br />
              {blockedItem.ipv4 ? (
                <>
                  <Text variant="bodySm" as="span">
                    IPv4: {blockedItem.ipv4}
                  </Text>
                  <br />
                </>
              ) : null}
              {findIPRange(blockedItem.ip) !== null ? (
                <Text variant="bodySm" as="span">
                  {`IP range: ${findIPRange(blockedItem.ip)}`}
                </Text>
              ) : null}
            </RegularText>
            <span className="ml-8 pointer">
              <Tooltip content="Copy">
                <span
                  className="btn-copy"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyIPAdress();
                  }}
                >
                  <Icon source={DuplicateMinor} />
                </span>
              </Tooltip>
            </span>
          </span>
        ),
        country: (
          <Tooltip dismissOnMouseOut content={handleCountry.renderCountry(blockedItem.countryCode)}>
            <ReactCountryFlag svg className="emojiFlag" countryCode={blockedItem.countryCode} />
          </Tooltip>
        ),
        city: <RegularText>{[blockedItem.regionName, blockedItem.cityName].filter((item) => !!item).join(' - ')}</RegularText>,
        totalAccess: (
          <p style={{ width: '4rem', textAlign: 'end' }}>
            <RegularText>
              <Link
                removeUnderline
                onClick={() => {
                  dispatch(
                    slice.blockedVisitorSlice.actions.handleBlockedDetail({
                      ...blockedDetail,
                      page: 1,
                      sort: Enum.SortType.DESC,
                      sortBy: 'accessAt',
                    }),
                  );
                  navigate({
                    ...PATH.BLOCKED_DETAIL,
                    pathname: `/analytics/blocked/${blockedItem.ip}`,
                  });
                }}
              >
                {blockedItem.totalAccess}
              </Link>
            </RegularText>
          </p>
        ),
        lastSeenOn: <RegularText>{formatDate(blockedItem.lastSeenOn)}</RegularText>,
        ispName: userPlanFree ? '---' : <RegularText>{blockedItem.provider || '---'}</RegularText>,
        zipCode: <RegularText>{blockedItem.zipCode || '---'}</RegularText>,
        risk: (
          <RegularText>
            {badgeRisk?.tooltip ? (
              userPlanFree ? (
                <div className="pointer text-decoration" onClick={() => navigate(PATH.PRICING_PLAN)}>
                  <Badge tone="warning">Needs upgrade</Badge>
                </div>
              ) : (
                <Tooltip content={badgeRisk?.tooltip}>
                  <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
                </Tooltip>
              )
            ) : (
              <Badge tone={badgeRisk?.tone}>{riskScore === null ? 'N/A' : String(riskScore)}</Badge>
            )}
          </RegularText>
        ),
        vpnStatus: userPlanFree ? (
          <Tooltip content="Available on higher plan">---</Tooltip>
        ) : (
          <Badge tone={isVpn ? 'warning' : 'success'}>{isVpn ? 'Yes' : 'No'}</Badge>
        ),
      };
    });
  }, [data?.listIp, handleCountry, userPlanFree, dispatch, blockedDetail, navigate]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ id, ipBlocked, city, country, lastSeenOn, totalAccess, ispName, risk, vpnStatus, zipCode }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{ipBlocked}</IndexTable.Cell>
        <IndexTable.Cell>{country}</IndexTable.Cell>
        <IndexTable.Cell>{city}</IndexTable.Cell>
        <IndexTable.Cell>{totalAccess}</IndexTable.Cell>
        <IndexTable.Cell>{lastSeenOn}</IndexTable.Cell>
        <IndexTable.Cell>{ispName}</IndexTable.Cell>
        <IndexTable.Cell>{zipCode}</IndexTable.Cell>
        <IndexTable.Cell id="risk-score">{risk}</IndexTable.Cell>
        <IndexTable.Cell id="vpn-status">{vpnStatus}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={items?.length || 0}
        emptyState={isFetching ? <SkeletonBodyText lines={20} /> : <EmptyState />}
        headings={[
          { title: <RegularText>IP blocked</RegularText>, id: '1' },
          { title: <RegularText>Country</RegularText>, id: '2' },
          { title: <RegularText>State/Province/City</RegularText>, id: '3' },
          { title: <RegularText>Total access</RegularText>, id: '4' },
          {
            title: <RegularText>Last seen on</RegularText>,
            id: '5',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Internet provider</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan /> : null}
              </div>
            ),
            id: '6',
          },
          {
            title: <RegularText>Zip code</RegularText>,
            id: '7',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>Risk score</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: '8',
          },
          {
            title: (
              <div className="d-flex va-risk-score">
                <RegularText>VPN/Proxy</RegularText>
                {userPlanFree ? <TooltipUpdateHigherPlan url={urlRickScore} /> : null}
              </div>
            ),
            id: '9',
          },
        ]}
        selectable={false}
      >
        {isFetching ? <SkeletonBodyText lines={20} /> : rowMarkup}
      </IndexTable>

      <div className="mt-16 mb-16 table-pagination">
        {data && data?.meta.totalItems > 0 ? (
          <Pagination
            label={
              data?.meta.totalItems
                ? `Showing ${(blockHistoryTable.page - 1) * Number(perPage) + 1} to ${Math.min(
                    blockHistoryTable.page * Number(perPage),
                    data?.meta.totalItems,
                  )} of ${data?.meta.totalItems} visitors`
                : null
            }
            hasPrevious={data && data?.meta?.currentPage > 1 && !isFetching}
            onPrevious={() => {
              dispatch(
                blockedVisitorSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page - 1,
                }),
              );
            }}
            hasNext={data && data?.meta?.currentPage < Math.ceil((data?.meta?.totalItems ?? 0) / Number(perPage)) && !isFetching}
            onNext={() => {
              dispatch(
                blockedVisitorSlice.actions.handleBlockHistoryTable({
                  ...blockHistoryTable,
                  page: blockHistoryTable.page + 1,
                }),
              );
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(TableBlocked);
