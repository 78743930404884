import BannerGrantPermission from '@/components/BannerGrantPermission';
import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import { Enum, PATH } from '@/constants';
import { BREAKPOINT, ConditionHidePayment, ScopeGroups } from '@/constants/enum';
import options, { conditionToCriteriaMapping } from '@/constants/options';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import hidePaymentSlice, { hidePaymentTableSelector, initialState, openModalSelector } from '@/redux/slice/hidePayment.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import {
  Card,
  IndexFilters,
  IndexFiltersMode,
  InlineGrid,
  LegacyCard,
  Modal,
  Text,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { HidePaymentFilter } from './components/HidePaymentsTable/Filter';
import { HidePaymentStyled } from './styled';
import TableHidePayments from '@/pages/Hide-payment/components/HidePaymentsTable/TableHidePayments';

const tabsConfig = [
  {
    id: 'hide-payment',
    content: 'Blacklist',
    panelID: 'hide-payment',
    children: <TableHidePayments />,
  },
];

const HidePayment = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });

  const dataSettings = useSelector(dataSettingsSelector);
  const isOpenGuide = useSelector(openModalSelector);

  const [deleteHidePaymentItem, deleteHidePaymentItemStatus] = apiCaller.useDeleteHidePaymentRulesMutation();

  const [state, setState] = useState({
    isOpenModalDelete: false,
  });
  const [clearSelectedChildFn, setClearSelectedChildFn] = useState<(() => void) | null>(null);

  const listRuleOptions = options.configRulesPayment.filter(
    (rule) =>
      rule.id !== Enum.CriteriaHidePayment.RepeatCustomer &&
      rule.id !== Enum.CriteriaHidePayment.CustomerTags &&
      rule.id !== Enum.CriteriaHidePayment.CustomerTotalSpent &&
      rule.id !== Enum.CriteriaHidePayment.EmailDomain &&
      rule.id !== Enum.CriteriaHidePayment.Phone &&
      rule.id !== Enum.CriteriaHidePayment.ZipCode &&
      rule.id !== Enum.CriteriaHidePayment.State &&
      rule.id !== Enum.CriteriaHidePayment.City,
  );

  const handleOpenModalDeleteAll = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: true,
    });
  }, [state]);

  const handleCloseModalDelete = useCallback(() => {
    setState({
      ...state,
      isOpenModalDelete: false,
    });
  }, [state]);

  const onParentAction = useCallback((action: () => void) => {
    setClearSelectedChildFn(() => action);
  }, []);

  const handleDeleteAllHidePaymentRules = useCallback(() => {
    deleteHidePaymentItem({
      ids: undefined,
    }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        clearSelectedChildFn?.();
        handleCloseModalDelete();
      }
    });
  }, [clearSelectedChildFn, deleteHidePaymentItem, dispatch, handleCloseModalDelete]);

  const handleModalClick = useCallback(
    (id: ConditionHidePayment) => {
      dispatch(slice.hidePaymentSlice.actions.handleChangeSelectedCondition(id));
      dispatch(
        hidePaymentSlice.actions.clearSettingHidePayment({
          ...initialState.itemSelected,
          criteria: conditionToCriteriaMapping[id],
        }),
      );
      dispatch(
        hidePaymentSlice.actions.handleVerifyErrorHidePayment({
          status: false,
          data: undefined,
        }),
      );
      navigate(PATH.HIDE_PAYMENT_DETAIL);
    },
    [dispatch, navigate],
  );

  // table
  const { mode, setMode } = useSetIndexFiltersMode();
  const hidePaymentTable = useSelector(hidePaymentTableSelector);
  const [inputSearch, setInputSearch] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchHidePayment = useCallback(
    _debounce((value: IParamsApi.IGetHidePaymentList) => {
      dispatch(hidePaymentSlice.actions.handleHidePaymentTable(value));
    }, 500),
    [dispatch],
  );

  const handleInputChangeTable = useCallback(
    (search: string) => {
      setInputSearch(search);
      debounceSetSearchHidePayment({
        ...hidePaymentTable,
        page: search ? 1 : hidePaymentTable.page,
        search,
      });
    },
    [hidePaymentTable, debounceSetSearchHidePayment],
  );

  const handleClearAllFilter = useCallback(() => {
    dispatch(
      slice.hidePaymentSlice.actions.handleHidePaymentTable({
        ...hidePaymentTable,
        perPage: '10',
        criteria: '',
      }),
    );
  }, [hidePaymentTable, dispatch]);

  return (
    <HidePaymentStyled isOpenGuide={isOpenGuide}>
      <div
        className="hide-payment-wrapper"
        onClick={() => {
          dispatch(hidePaymentSlice.actions.handleOpenModalHidePayment(false));
        }}
      />
      <BannerGrantPermission
        title="Required before starting a Hide Payment rule"
        isVisible={!dataSettings?.settings.user.paymentCustomizationScope}
        enum={ScopeGroups.paymentCustomization}
        children={
          <RegularText>
            To start the Hide Payment rule, we'd need to access the <b>Payment customization</b> permission.
          </RegularText>
        }
        variant="primary"
      />
      {!dataSettings?.settings.user.paymentCustomizationScope ? (
        <>
          <Tooltip content="Grant permission to start creating a rule">
            <div className="disable">
              <div className="add-rule-wrapper">
                <Card padding="200">
                  <div style={{ padding: isMobile ? '0rem' : '0.5rem' }}>
                    <InlineGrid columns={{ xs: 4, lg: 4 }} gap={{ xs: '300', md: '400' }}>
                      {listRuleOptions.map((item, index) => {
                        return (
                          <Card key={index} padding={'0'}>
                            <div key={index} className="add-rule-container">
                              <img className="add-rule-img" src={item.src} alt={item.title} />
                              <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                                {item.label}
                              </Text>
                            </div>
                          </Card>
                        );
                      })}
                    </InlineGrid>
                  </div>
                </Card>
              </div>
            </div>
          </Tooltip>
          <Tooltip content="Grant permission to start creating a rule" zIndexOverride={600}>
            <div className="disable">
              <div className="card-table">
                <Card padding={'0'}>
                  <IndexFilters
                    queryValue={inputSearch}
                    queryPlaceholder="Searching by country, email, phone number, customer, ... "
                    onQueryChange={() => {}}
                    onQueryClear={() => {}}
                    tabs={tabsConfig}
                    selected={0}
                    onSelect={() => {}}
                    canCreateNewView={false}
                    filters={HidePaymentFilter().filters}
                    appliedFilters={HidePaymentFilter().appliedFilters}
                    onClearAll={handleClearAllFilter}
                    cancelAction={{ onAction: () => {} }}
                    mode={mode}
                    setMode={setMode}
                  />
                  <TableHidePayments onParentAction={onParentAction} />
                </Card>
              </div>
            </div>
          </Tooltip>
        </>
      ) : (
        <>
          <div className="add-rule-wrapper">
            <Card padding="200">
              <div style={{ padding: isMobile ? '0rem' : '0.5rem' }}>
                <InlineGrid columns={{ xs: 4, lg: 4 }} gap={{ xs: '300', md: '400' }}>
                  {listRuleOptions.map((item, index) => {
                    return (
                      <Card key={index} padding={'0'}>
                        <div key={index} className="add-rule-container" onClick={() => handleModalClick(item.condition)}>
                          <img className="add-rule-img" src={item.src} alt={item.title} />
                          <Text variant="bodyMd" as="h6" alignment="center" breakWord>
                            {item.label}
                          </Text>
                        </div>
                      </Card>
                    );
                  })}
                </InlineGrid>
              </div>
            </Card>
          </div>

          <div className="card-table">
            {mode === IndexFiltersMode.Filtering ? null : (
              <div className="btn-delete">
                <Tooltip content="Delete all">
                  <CustomButton icon={DeleteMinor} onClick={handleOpenModalDeleteAll} />
                </Tooltip>
              </div>
            )}

            <LegacyCard>
              <IndexFilters
                queryValue={inputSearch}
                queryPlaceholder="Searching by country, email, phone number, customer, ... "
                onQueryChange={handleInputChangeTable}
                onQueryClear={() => {
                  handleInputChangeTable('');
                }}
                tabs={tabsConfig}
                selected={0}
                onSelect={() => {}}
                canCreateNewView={false}
                filters={HidePaymentFilter().filters}
                appliedFilters={HidePaymentFilter().appliedFilters}
                onClearAll={handleClearAllFilter}
                cancelAction={{ onAction: () => {} }}
                mode={mode}
                setMode={setMode}
              />
              <TableHidePayments onParentAction={onParentAction} />
            </LegacyCard>
          </div>
        </>
      )}

      <Modal
        open={state.isOpenModalDelete}
        onClose={handleCloseModalDelete}
        title="Delete all"
        primaryAction={{
          destructive: true,
          content: 'Delete',
          onAction: handleDeleteAllHidePaymentRules,
          loading: deleteHidePaymentItemStatus.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModalDelete,
          },
        ]}
      >
        <Modal.Section>
          <RegularText>If you deleted all rules, you won't be able to revert it</RegularText>
        </Modal.Section>
      </Modal>
    </HidePaymentStyled>
  );
};
export default memo(HidePayment);
