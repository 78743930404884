import CustomButton from '@/components/CustomButton';
import { config } from '@/config';
import { dateToTimeStamp } from '@/helpers';
import slice from '@/redux/slice';
import { allAccessSelector, tabsTableSelector } from '@/redux/slice/visitorAnalytics.slice';
import {
  Card,
  IndexFilters,
  IndexFiltersMode,
  IndexFiltersProps, Tooltip,
  useSetIndexFiltersMode
} from '@shopify/polaris';
import { ExportMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableBlocked from './BlockedTable';
import useConfigTable from './ConfigTable';
import TableVisitorLog from './VisitorAnalytics';

const TableAnalytics = () => {
  const dispatch = useDispatch();
  const configTable = useConfigTable();
  const { mode, setMode } = useSetIndexFiltersMode();
  const selected = useSelector(tabsTableSelector);
  const allAccess = useSelector(allAccessSelector);
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(slice.visitorAnalyticsSlice.actions.handleTableTab(selectedTabIndex)),
    [dispatch],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = [
    {
      id: 'visitorLog',
      content: 'Allowed visitors',
      component: <TableVisitorLog />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/export?shop=${config.shop}&urlParams=${JSON.stringify(
        config.urlParams,
      )}&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
    {
      id: 'blocked',
      content: 'Blocked visitors',
      component: <TableBlocked />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/block/export?shop=${config.shop}&urlParams=${JSON.stringify(
        config.urlParams,
      )}&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
  ];

  const configFilter = useMemo(() => {
    const key = tabs[selected].id;
    const result = {
      ...configTable[key as keyof typeof configTable],
    };
    return result;
  }, [configTable, selected, tabs]);

  return (
    <div className="table-container">
      <Card padding={'100'}>
        <IndexFilters
          {...configFilter}
          sortOptions={configFilter.sortOptions as IndexFiltersProps['sortOptions']}
          cancelAction={{
            onAction: () => { },
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <div className="export-btn" style={{ display: mode === IndexFiltersMode.Default ? 'block' : 'none' }}>
          <Tooltip content={'Export csv'}>
            <CustomButton icon={ExportMinor} url={tabs[selected].exportUrl} target="_blank" />
          </Tooltip>
        </div>
        {tabs[selected].component}
      </Card>
    </div>
  );
};

export default memo(TableAnalytics);
