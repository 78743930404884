import { images } from '@/asset';
import RegularText from '@/components/RegularText';
import { link } from '@/constants/link';
import { Link, Text } from '@shopify/polaris';
import { memo } from 'react';

const StepTwo = () => {
  const handleOpenTheme = () => {
    window.open(link.isOpenAppEmbedUrl, '_blank');
  };
  return (
    <div className="step-two">
      <Text as="h3" variant="headingXl">
        Don’t forget to turn on Blockify
      </Text>
      <div className="mt-8 text-align">
        <RegularText>
          For Blockify <b>works seamlessly</b> with your theme, please make sure to <b>turn on</b> the app in the{' '}
          <Link removeUnderline external onClick={handleOpenTheme}>
            Theme Editor
          </Link>
          .
        </RegularText>
      </div>
      <img src={images.openTheme} alt="_blank" />
    </div>
  );
};

export default memo(StepTwo);
