import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
const initialState = {
  currentStep: 0,
};

const homeSlice = createSlice({
  name: 'home',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const currentStepSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.currentStep,
);

export default homeSlice;
