import styled from 'styled-components';

export const CheckoutRulesBannerStyled = styled.div`
  position: relative;
  margin-top: 1.25rem;
  cursor: pointer;

  img {
    width: 100%;
  }
  .btn-hide-banner {
    position: absolute;
    padding: 1px;
    top: 0;
    right: 0;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 2px;
    cursor: pointer;
    svg {
      fill: #fff;
    }
  }
`;
