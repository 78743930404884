import Layout from '@/components/layout';
import { apiCaller } from '@/redux/query';
import { Card } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import Canny from './Canny';

const Feedback = () => {
    const [ssoToken, setSSOToken] = useState<string>('');
    const { data } = apiCaller.useGetSSOCannyTokenQuery();
    useEffect(() => {
        if (data) {
            setSSOToken(data.token);
        }
    }, [data]);

    return (
        <Layout
            layoutProps={{
                title: 'Feedback',
            }}
        >
            <Card>
                <Canny ssoToken={ssoToken} />
            </Card>
        </Layout>
    );
};

export default Feedback;