import { images } from '@/asset';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import { Badge, Card, Icon, InlineGrid, Link, SkeletonDisplayText, Text, Image } from '@shopify/polaris';
import {
  BugMajor,
  CircleAlertMajor,
  CircleDisabledMajor,
  CustomersMajor,
  MobileAcceptMajor,
  RiskMajor,
} from '@shopify/polaris-icons';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const Analytics = () => {
  const navigate = useNavigate();
  const allAccess = useSelector(allAccessSelector);
  const { userPlanFree } = userPlans();
  const blockedIps = apiCaller.useFetchChartBlockQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const visitors = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const dataChart = useMemo(() => {
    const result = [
      {
        title: 'Total allowed visitors',
        value: visitors.data?.data.totalVisit,
        icon: CustomersMajor,
      },
      {
        title: 'Total blocked visitors',
        value: blockedIps.data?.data.totalBlocked,
        icon: CircleDisabledMajor,
      },
      {
        title: 'Total blocked VPN & Proxy',
        value: blockedIps.data?.data.totalVpn,
        icon: BugMajor,
      },
      {
        title: 'Low risk IP',
        value: visitors.data?.data.totalSafeIp,
        icon: MobileAcceptMajor,
      },
      {
        title: 'High risk IP',
        value: visitors.data?.data.totalRiskIp,
        icon: RiskMajor,
      },
      {
        title: 'Dangerous IP',
        value: visitors.data?.data.totalDangerousIp,
        icon: CircleAlertMajor,
      },
    ];
    return result;
  }, [blockedIps.data, visitors.data]);
  return (
    <Card>
      <BoldText>Overview</BoldText>
      <div className="analytics-container mt-16">
        <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
          {dataChart.map((item, index) => {
            return (
              <Card key={index}>
                <div className="analytics-items-title">
                  <Icon source={item.icon} tone="base" />
                  <div className="d-flex">
                    <div className="mr-8">
                      <Text fontWeight="medium" as="h5" variant="headingSm">
                        {item.title}
                      </Text>
                    </div>
                    {userPlanFree && item.title !== 'Total allowed visitors' && item.title !== 'Total blocked visitors' ? (
                      <Badge tone="warning">Paid plan</Badge>
                    ) : null}
                  </div>
                </div>
                <div className="mt-8">
                  {blockedIps.isFetching || visitors.isFetching ? (
                    <SkeletonDisplayText />
                  ) : (
                    <div>
                      {userPlanFree ? (
                        <div>
                          {item.title !== 'Total allowed visitors' && item.title !== 'Total blocked visitors' ? (
                            <RegularText>
                              <Text as="h5" variant="heading2xl" fontWeight="regular">
                                <Image source={images.lockFreePlanVA} alt={'lock-image'}></Image>
                              </Text>
                              <Link
                                onClick={() =>
                                  navigate(PATH.PRICING_PLAN)
                                }
                              >
                                Upgrade
                              </Link>{' '}
                              to see this metric
                            </RegularText>
                          ) : (
                            <Text as="h5" variant="heading2xl" fontWeight="regular">
                              {(item.value || 0) < 20000 ? item.value : `${item.value}+`}
                            </Text>
                          )}
                        </div>
                      ) : (
                        <Text as="h5" variant="heading2xl" fontWeight="regular">
                          {item.value}
                        </Text>
                      )}
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      </div>
    </Card>
  );
};

export default memo(Analytics);
