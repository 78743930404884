import { Enum } from '@/constants';
import { getSomeDaysAgo } from '@/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface BlockedDetail {
  startDate: Date;
  endDate: Date;
  page: number;
  perPage: string;
  sortBy: string;
  sort: string;
}

export interface ListBlock {
  search?: string;
  risk?: string;
  page: number;
  sortBy: string;
  sort: string;
  countriesCode?: Array<string>;
  providers?: Array<string>;
  Locations?: Array<string>;
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  blockHistoryTable: ListBlock;
  blockedDetail: BlockedDetail;
  inputSearch: string;
  titleBtnDatePicker: string;
  titleBtnDatePickerBlockedDetail: string;
} = {
  blockHistoryTable: {
    search: '',
    page: 1,
    sort: Enum.SortType.DESC,
    sortBy: 'lastSeenOn',
    countriesCode: [],
  },
  blockedDetail: {
    page: 1,
    perPage: '10',
    sort: 'desc',
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    sortBy: 'accessAt',
    startDate: getSomeDaysAgo(7),
  },
  inputSearch: '',
  titleBtnDatePicker: 'Last 7 days',
  titleBtnDatePickerBlockedDetail: 'Last 7 days',
};

export const blockedVisitorSlice = createSlice({
  name: 'blockHistory',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleBlockHistoryTable: (state, action: PayloadAction<ListBlock>) => {
      state.blockHistoryTable = action.payload;
    },
    handleBlockedDetail: (state, action: PayloadAction<BlockedDetail>) => {
      state.blockedDetail = action.payload;
    },
    handleInputSearch: (state, action: PayloadAction<string>) => {
      state.inputSearch = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
    handleTitleBtnDatePickerBlockedDetail: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePickerBlockedDetail = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const blockHistorySelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.blockHistoryTable,
);
export const blockedDetailSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.blockedDetail,
);
export const inputSearchSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.inputSearch,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.titleBtnDatePicker,
);

export const titleBtnDatePickerBlockedDetailSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.titleBtnDatePickerBlockedDetail,
);

export default blockedVisitorSlice;
