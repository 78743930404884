import BlockPageStoreFront from '@/components/BlockPageStoreFront';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { Enum, PATH } from '@/constants';
import { deepObjectEqual } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import settingsSlice, {
  cssBackupSelector,
  cssSelector,
  selectedTabSelector,
  templateIdSelector,
  templateSavedSelector,
  templateSelector,
} from '@/redux/slice/settings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Fullscreen } from '@shopify/app-bridge/actions';
import { Button, Card, Divider, FullscreenBar, Tabs, Text } from '@shopify/polaris';
import { ViewMajor } from '@shopify/polaris-icons';
import mixpanel from 'mixpanel-browser';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomCSS from './component/CustomCSS';
import Editor from './component/Editor';
import Template from './component/Template';
import { PreviewStyled } from './styled';
interface IFileProps {
  setFiles: (value: { image: null | File; backgroundImage: null | File }) => void;
  files: { image: null | File; backgroundImage: null | File };
}

// const btnGroup = [
//   {
//     icon: DesktopMajor,
//     type: 'desktop',
//   },
//   // {
//   //   icon: MobileMajor,
//   //   type: 'mobile',
//   // },
// ];

const PreviewAdmin = ({ ...props }: IFileProps) => {
  const navigate = useNavigate();

  return (
    <FullscreenBar
      onAction={() => {
        navigate(PATH.SETTINGS);
      }}
    >
      <PreviewTitle {...props} />
    </FullscreenBar>
  );
};

function PreviewAppbridge({ ...props }: IFileProps) {
  const app = useAppBridge();
  const fullscreen = Fullscreen.create(app);
  const navigate = useNavigate();
  app.subscribe(Fullscreen.Action.EXIT, () => {
    navigate(PATH.SETTINGS);
  });
  return (
    <FullscreenBar
      onAction={() => {
        fullscreen.dispatch(Fullscreen.Action.EXIT);
        navigate(PATH.SETTINGS);
      }}
    >
      <PreviewTitle {...props} />
    </FullscreenBar>
  );
}

const PreviewTitle = ({ ...props }: IFileProps) => {
  const dispatch = useDispatch();
  const template = useSelector(templateSelector);
  const templateSaved = useSelector(templateSavedSelector);
  const templateId = useSelector(templateIdSelector);
  const css = useSelector(cssSelector);
  const cssBackup = useSelector(cssBackupSelector);
  const [updateCSS] = apiCaller.useUpdateCustomizeCSSMutation();
  const [uploadImage] = apiCaller.useUploadImageMutation();
  const [uploadTemplate, { isLoading }] = apiCaller.useUpdateTemplateMutation();

  const handleUpdateTemplate = () => {
    const update = async () => {
      try {
        let newTemplate = {
          ...template,
        };

        if (props.files.image) {
          const formDataLogo = new FormData();
          formDataLogo.append('image', props.files.image);
          const logo = await uploadImage(formDataLogo);

          if ('data' in logo) {
            newTemplate = {
              ...newTemplate,
              logoImage: {
                ...newTemplate?.logoImage,
                value: logo.data.url,
              },
            };
          }
        }

        if (props.files.backgroundImage && Enum.BackgroundType.Image) {
          const formDataBackground = new FormData();
          formDataBackground.append('image', props.files.backgroundImage);
          const background = await uploadImage(formDataBackground);
          if ('data' in background) {
            newTemplate = {
              ...newTemplate,
              background: {
                ...newTemplate.background,
                value: background.data.url,
              },
            };
          }
        }
        await uploadTemplate({
          properties: newTemplate,
          templateId: templateId,
        });
        await updateCSS({
          cssCode: css,
        });
        props.setFiles({
          image: null,
          backgroundImage: null,
        });

        dispatch(
          settingsSlice.actions.handleTemplateSaved({
            ...newTemplate,
          }),
        );
        dispatch(
          settingsSlice.actions.handleTemplate({
            ...newTemplate,
          }),
        );
        dispatch(settingsSlice.actions.handleCssBackup(css));
        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Updated',
            error: false,
          }),
        );
      } catch (e) {
        console.log(e);

        dispatch(
          toastSlice.actions.handleToast({
            isOpen: true,
            content: 'Save failure',
            error: false,
          }),
        );
      }
    };
    update();
  };

  return (
    <div className="preview-title">
      <Text as="span" variant="headingLg">
        Blocking page
      </Text>
      <div className="group-btn d-flex">
        <Button
          disabled={
            css === cssBackup && deepObjectEqual(template, templateSaved) && !props.files.image && !props.files.backgroundImage
          }
          onClick={() => {
            dispatch(
              settingsSlice.actions.handleInput({
                titleColor: templateSaved.title.color,
                backgroundColor: templateSaved.background.value,
                contentColor: templateSaved.description.color,
                superTitleColor: templateSaved.superTitle.color,
                backgroundGradientFrom: templateSaved.background.colorFrom || '',
                backgroundGradientTo: templateSaved.background.colorTo || '',
              }),
            );
            dispatch(
              settingsSlice.actions.handleTemplate({
                ...templateSaved,
              }),
            );
            dispatch(settingsSlice.actions.handleCss(cssBackup));
          }}
        >
          Discard
        </Button>
        <div className="ml-8">
          <Button
            variant="primary"
            loading={isLoading}
            disabled={
              css === cssBackup && deepObjectEqual(template, templateSaved) && !props.files.image && !props.files.backgroundImage
            }
            onClick={handleUpdateTemplate}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const Preview = () => {
  const [files, setFiles] = useState<{ image: null | File; backgroundImage: null | File }>({
    image: null,
    backgroundImage: null,
  });
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectedTabSelector);
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(settingsSlice.actions.handleSelectedTab(selectedTabIndex)),
    [dispatch],
  );
  const [focusId, setFocusId] = useState('');
  const [activeButtonIndex] = useState(0);
  const dataSettings = useSelector(dataSettingsSelector);
  const [trackAction] = apiCaller.useTrackActionMutation();

  const handleOpenTestBlock = () => {
    window.open(dataSettings?.settings.testBlockUrl);
  };
  // const handleButtonClick = useCallback(
  //   (index: number) => {
  //     if (activeButtonIndex === index) return;
  //     setActiveButtonIndex(index);
  //   },
  //   [activeButtonIndex],
  // );

  const removeWaterMark = () => {
    mixpanel?.track('Remove_watermark');
    const sendText = 'I want to remove brand mark in the blocking page';
    try {
      $crisp.push(['do', 'chat:open']);
      $crisp.push(['do', 'message:send', ['text', sendText]]);
    } catch (err) {
      console.log(err);
    }
    trackAction('remove_water_mark');
  };

  const tabs = useMemo(() => {
    return [
      {
        id: 'template',
        content: 'Template',
        fields: <Template />,
      },
      {
        id: 'style',
        content: 'Style',
        fields: <Editor files={files} setFiles={setFiles} setFocusId={setFocusId} />,
      },
      {
        id: 'css',
        content: 'Custom CSS',
        fields: <CustomCSS />,
      },
    ];
  }, [files]);

  return (
    <PreviewStyled>
      {config.embedded === '1' ? (
        <PreviewAppbridge files={files} setFiles={setFiles} />
      ) : (
        <PreviewAdmin files={files} setFiles={setFiles} />
      )}
      <div className="preview-container">
        <div className="preview-fields Polaris-Box">
          <Tabs tabs={tabs} fitted selected={selectedTab} onSelect={handleTabChange}>
            <div className="preview-fields-content">
              <div>{tabs[selectedTab].fields}</div>
              {dataSettings?.settings.user.plan === Enum.UserPlan.FREE && dataSettings.settings.showWatermark ? (
                <div className="preview-remove-watermark">
                  <BoldText>Remove Blockify branding</BoldText>
                  {/* <Checkbox label="Remove “Powered by Blockify”" checked={false} disabled /> */}
                  <div className="mb-8">
                    <RegularText>If you like to remove “Powered by Blockify” brand logo for free, please contact us!</RegularText>
                  </div>
                  <Button variant="primary" onClick={removeWaterMark}>
                    Contact us
                  </Button>
                </div>
              ) : null}
            </div>
          </Tabs>
        </div>

        <div className="preview-blocking-page">
          <div className={activeButtonIndex === 0 ? 'preview-blocking-page-fullscreen' : 'preview-blocking-page-mobile'}>
            <Card>
              {/* <div className="d-flex flex-center mt-8 mb-8">
                  {btnGroup.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        icon={item.icon}
                        variant={activeButtonIndex === index ? 'primary' : undefined}
                        onClick={() => handleButtonClick(index)}
                      />
                    );
                  })}
                </div> */}
              <div className="btn-preview">
                <Button icon={ViewMajor} onClick={handleOpenTestBlock}></Button>
              </div>
              <Divider />
              <BlockPageStoreFront focusId={focusId} />
            </Card>
          </div>
        </div>
      </div>
    </PreviewStyled>
  );
};

export default memo(Preview);
