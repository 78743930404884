import { apiCaller } from '@/redux/query';
import { useMemo } from 'react';

const otherCountry = [
  {
    value: 'all',
    label: 'Add all countries',
  },
  {
    value: 'allEu',
    label: 'Add EU countries',
  },
  {
    value: 'nonEu',
    label: 'Add all Non-EU countries',
  },
  {
    value: 'nonUs',
    label: 'Add all Non-US countries',
  },
];

interface Props {
  countryCode?: string;
  stateName?: string;
}

const useCountry = (props?: Props) => {
  const fetchCountries = apiCaller.useGetCountryQuery();
  const fetchStates = apiCaller.useGetStateQuery({ countryCode: props?.countryCode || '' }, { skip: !props?.countryCode });
  const fetchCities = apiCaller.useGetCityQuery(
    { countryCode: props?.countryCode || '', regionName: props?.stateName || undefined },
    { skip: !props?.countryCode },
  );
  const listCountry = useMemo(() => {
    if (fetchCountries.data) {
      const result = fetchCountries.data?.result.map((item) => {
        return {
          label: item.countryName,
          value: item.countryCode,
        };
      });
      return result;
    }
    return [];
  }, [fetchCountries.data]);

  const listStates = useMemo(() => {
    if (fetchStates.data) {
      const result = fetchStates.data?.result.map((item) => {
        return {
          label: item.regionName,
          value: item.regionName,
        };
      });
      return result;
    }
    return [];
  }, [fetchStates.data]);

  const listCities = useMemo(() => {
    if (fetchCities.data) {
      const result = fetchCities.data?.result.map((item) => {
        return {
          label: item.cityName,
          value: item.cityName,
        };
      });
      return result;
    }
    return [];
  }, [fetchCities.data]);

  const renderCountry = (countryCode: string) => {
    if (listCountry) {
      const countries = [...otherCountry, ...listCountry];
      const result = countries.find((item) => item.value === countryCode)?.label;
      return result;
    }
    return '';
  };

  return {
    listCountry,
    renderCountry,
    otherCountry,
    listStates,
    listCities: {
      value: listCities,
      isFetching: fetchCities.isFetching,
    },
  };
};

export default useCountry;
