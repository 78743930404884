/* eslint-disable jsx-a11y/anchor-is-valid */
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { IGA4 } from '@/types/components';
import { Button, Card, InlineGrid } from '@shopify/polaris';
import { CancelMinor } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RightRecommendApps from './Slide';
import { leftRecommendApp } from './configRecommend';
import { RecommendedAppsStyled } from './styled';
import { showRecommendedAppsSelector } from '@/redux/slice/session.slice';

const RecommendedApps = () => {
  const dispatch = useDispatch();

  const [impressBanner, setImpressBanner] = useState(false);
  const [fetchGA] = apiCaller.useFetchGAMutation();

  const showRecommendedApps = useSelector(showRecommendedAppsSelector);
  const handleTurnOffRecommendedApp = useCallback(() => {
    dispatch(slice.session.actions.handleShowRecommendedApps(false));
  }, [dispatch]);

  const handleClick = useCallback(
    (item: IGA4) => () => {
      // if (item.title === 'Synctrack Omega Order Tracking') {
      //   mixpanel?.track('SF_recommend_order_tracking');
      // } else {
      //   mixpanel?.track('SF_recommend_return');
      // }
      window.open(item.href, '_blank', 'noopener,noreferrer');
    },
    [],
  );

  useEffect(() => {
    const handleScroll = () => {
      const bannerTemplate = document.getElementById('banner-2');
      if (bannerTemplate) {
        const bannerPosition = bannerTemplate.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (bannerPosition < windowHeight && !impressBanner) {
          fetchGA({
            eventName: 'impress_homebanner2',
          });
          fetchGA({
            eventName: 'impress_homebanner3',
          });
          setImpressBanner(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [impressBanner, fetchGA]);
  return (
    <>
      {showRecommendedApps ? (
        <RecommendedAppsStyled>
          <div className="btn-cancel">
            <Button variant="plain" icon={CancelMinor} onClick={handleTurnOffRecommendedApp} />
          </div>
          <BoldText>Recommended apps</BoldText>
          <div className="mt-16">
            <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={'400'}>
              <div className="main-recommend-app">
                <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} gap={'400'}>
                  {leftRecommendApp.map((item) => {
                    return (
                      <Card padding={'400'} key={item.title}>
                        <div className="recommend-app-container">
                          <div className="recommend-app-content">
                            <img src={item.logo} alt={item.title} />
                            <div>
                              <BoldText>{item.title}</BoldText>
                            </div>
                            <RegularText>{item.description}</RegularText>
                          </div>
                          <Button onClick={handleClick(item)}>Check now</Button>
                        </div>
                      </Card>
                    );
                  })}
                </InlineGrid>
              </div>
              <RightRecommendApps />
            </InlineGrid>
          </div>
        </RecommendedAppsStyled>
      ) : null}
    </>
  );
};

export default memo(RecommendedApps);
