import { config } from '@/config';
import { PATH } from '@/constants';
import customersList from '@/constants/customerHidePayment';
import { UserPlan } from '@/constants/enum';
import { isDevelopmentPlan } from '@/helpers';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import welcomeSlice from '@/redux/slice/welcome.slice';
import { ILayout } from '@/types/components';
import { Button, ButtonGroup, Link, Page } from '@shopify/polaris';
import { AnalyticsMajor, PlanMajor, SecureMajor, SettingsMajor, ToolsMajor } from '@shopify/polaris-icons';
import { memo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import RegularText from '../RegularText';
import { LayoutStyled } from './styled';

const btnGroup = [
  {
    content: 'Home',
    icon: SecureMajor,
    path: PATH.DEFAULT,
  },
  {
    content: 'Visitor Analytics',
    icon: AnalyticsMajor,
    path: PATH.ANALYTICS,
  },
  {
    content: 'Blocker & Re-director ',
    icon: ToolsMajor,
    path: PATH.BLOCKLIST,
  },
  {
    content: 'Fraud orders analytics',
    icon: ToolsMajor,
    path: PATH.FRAUD_ORDER,
  },
  {
    content: 'Block checkout',
    icon: ToolsMajor,
    path: PATH.BLOCKED_CHECKOUT,
  },

  customersList.includes(config.shop)
    ? {
        content: 'Checkout rules',
        icon: ToolsMajor,
        path: PATH.CHECKOUT_RULES,
      }
    : undefined,

  {
    content: 'Settings',
    icon: SettingsMajor,
    path: PATH.SETTINGS,
  },
  {
    content: 'Feedback',
    icon: SettingsMajor,
    path: PATH.FEEDBACK,
  },
  {
    content: 'Pricing Plan',
    icon: PlanMajor,
    path: PATH.PRICING_PLAN,
  },
  {
    content: 'Development',
    icon: PlanMajor,
    path: PATH.CONTACT_TO_USE_DEVELOPMENT_STORE,
    hide: true,
  },
];

const Layout = ({ children, layoutProps, isVisibleHeader = true }: ILayout.IProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const dataSettings = useSelector(dataSettingsSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    if (dataSettings && dataSettings.settings) {
      if (dataSettings.settings.displayWelcome) {
        dispatch(welcomeSlice.actions.handleChangeWelcomeStep(dataSettings.settings.welcomeStep));
        navigate(PATH.WELCOME);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSettings)]);
  const isDevPlan = isDevelopmentPlan(dataSettings?.settings.shopifyPlan || '');
  const isFreePlan = dataSettings?.settings.user.plan === UserPlan.FREE;
  // useLayoutEffect(() => {
  //   if (isFreePlan && isDevPlan) {
  //     navigate(PATH.CONTACT_TO_USE_DEVELOPMENT_STORE);
  //   }
  // }, [isDevPlan, isFreePlan, navigate]);

  // useLayoutEffect(() => {
  //   if (location.pathname === PATH.CONTACT_TO_USE_DEVELOPMENT_STORE.pathname && !isDevPlan) {
  //     navigate(PATH.DEFAULT);
  //   }
  // }, [location.pathname, navigate, isDevPlan]);

  return (
    <LayoutStyled>
      {isVisibleHeader && config.embedded !== '1' ? (
        <div className="nav-bar">
          <ButtonGroup fullWidth gap="extraTight">
            {btnGroup
              .filter((item) => item !== undefined)
              .filter((item) => item?.hide !== true)
              .filter((item) => item?.path !== PATH.HIDE_PAYMENT)
              .map((item, index) => {
                return (
                  <Button
                    key={index}
                    icon={item?.icon}
                    variant={
                      location.pathname === item?.path.pathname || (index === 0 && location.pathname === '/')
                        ? 'primary'
                        : undefined
                    }
                    onClick={() => {
                      navigate({
                        ...item?.path,
                      });
                    }}
                  >
                    {item?.content}
                  </Button>
                );
              })}
          </ButtonGroup>
        </div>
      ) : null}

      <Page {...layoutProps}>
        <div>{children}</div>
        <RegularText>
          <div className="layout-footer">
            Need help? Please view&nbsp;{' '}
            <Link removeUnderline external url="https://docs.ipblocker.io/">
              our document guideline
            </Link>
          </div>
        </RegularText>
      </Page>
    </LayoutStyled>
  );
};
export default memo(Layout);
