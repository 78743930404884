import BoldText from '@/components/BoldText';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { welcomeSlice, welcomeStepSelector } from '@/redux/slice/welcome.slice';
import { Button, Card } from '@shopify/polaris';
import React, { memo, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StepFour from './components/StepFour';
import StepOne from './components/StepOne';
import StepThree from './components/StepThree';
import StepTwo from './components/StepTwo';
import { WelcomeStyled } from './styled';

const stepItems = [
  {
    key: 1,
    title: 'Welcome',
    content: <StepOne />,
  },
  {
    key: 2,
    title: 'Turn on Blockify',
    content: <StepTwo />,
  },
  {
    key: 3,
    title: 'Test Blockify',
    content: <StepThree />,
  },
  {
    key: 4,
    title: 'Finish',
    content: <StepFour />,
  },
];

export const welcomeContext = React.createContext<{ isChecked: boolean; setIsChecked: any }>({
  isChecked: false,
  setIsChecked: () => { },
});

const Welcome = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const step = useSelector(welcomeStepSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [updateWelcomeStep, { isLoading }] = apiCaller.useUpdateWelcomeStepMutation();
  const handleChangeStep = (stepInput: number) => () => {
    if (dataSettings) {
      dispatch(welcomeSlice.actions.handleChangeWelcomeStep(stepInput));
    }
  };
  const handleSkip = async () => {
    await updateWelcomeStep(step).then((res) => {
      if ('data' in res) {
        navigate(PATH.DEFAULT);
      }
    });
  };
  useLayoutEffect(() => {
    if (dataSettings && !dataSettings.settings.displayWelcome) {
      navigate(PATH.DEFAULT);
    }
  }, [dataSettings, navigate]);

  return (
    <welcomeContext.Provider value={{ isChecked, setIsChecked }}>
      <WelcomeStyled>
        <Card>
          <div className="welcome-container">
            <div className="welcome-process-container">
              <div className="welcome-process" style={{ maxWidth: `${(step - 1) * 25}%`, transition: 'all .5s' }} />
            </div>
            <ul>
              {stepItems.map((item) => {
                return (
                  <li
                    key={item.key}
                    onClick={handleChangeStep(item.key)}
                    className={step > item.key ? 'completed' : step === item.key ? 'active' : ''}
                  >
                    <div className="step-container" data-toggle="tab" aria-expanded="false">
                      <BoldText>{item.title}</BoldText>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content d-block">
              <div className="tab-panel">{stepItems.find((item) => item.key === step)?.content}</div>
            </div>
            <div className="button-center mt-20">
              {step !== 4 ? (
                <Button loading={isLoading} size="medium" onClick={handleChangeStep(step + 1)} variant="primary">
                  {step === 1 ? 'Get Started' : 'Next'}
                </Button>
              ) : (
                <Button loading={isLoading} size="medium" onClick={handleSkip} variant="primary">
                  Start Now
                </Button>
              )}

              {step !== 4 ? (
                <div className="mt-16">
                  <Button variant="plain" onClick={handleSkip}>
                    Skip
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </Card>
      </WelcomeStyled>
    </welcomeContext.Provider>
  );
};
export default memo(Welcome);
