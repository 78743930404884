import { recommendAppImage } from '@/asset/images/recommendedApps';

// export const configRecommend = [
//   {
//     title: 'Synctrack Auto',
//     subtitle: 'Tracking Sync',
//     description: 'Speed up PayPal verification to get funds faster, avoid chargebacks, and account limits.',
//     img: images.syntrack,
//     href: 'https://apps.shopify.com/synctrack?utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
//   },
//   {
//     title: 'Omega',
//     subtitle: 'Order Tracking',
//     description: 'Track your orders with a branded tracking page & automatic notifications. Reduce WISMO support tickets.',
//     img: images.orderTracking,
//     href: 'https://apps.shopify.com/omega-order-tracking?utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
//   },
//   {
//     title: 'Omega - Returns',
//     subtitle: ' & Exchanges',
//     description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue.',
//     img: images.returnDrive,
//     href: 'https://apps.shopify.com/omega-returns-drive?utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
//   },
//   {
//     title: 'Floatify - CTA,',
//     subtitle: 'Social Buttons',
//     description:
//       'With simple solution, customers can contact your shop faster with many tools on the same program to increase sales.',
//     img: images.CTA,
//     href: 'https://apps.shopify.com/cta-buttons??utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
//   },
// ];

export const leftRecommendApp = [
  {
    title: 'Synctrack PayPal Tracking Sync',
    description: 'Speed up PayPal verification to get funds faster, avoid chargebacks, and account limits.',
    logo: recommendAppImage.synctrack,
    href: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp',
  },
  {
    title: 'Synctrack ‑ Returns & Exchanges',
    description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue.',
    logo: recommendAppImage.returnDrive,
    href: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=blockify&utm_medium=homeinapp.',
  },
];

export const rightRecommendApp = [
  {
    title: 'Avada GDPR Cookie Consent',
    description: `Compliance with privacy laws is a must for global sellers. One solution ensures compliance across all regions - so you don't have to.`,
    logo: recommendAppImage.avada,
    href: 'https://apps.shopify.com/avada-cookie-bar?utm_source=Blockify&utm_medium=inappbanner&utm_campaign=cookieBar_partnership',
    label: 'Get Cookie',
  },
  {
    title: 'Floatify - CTA, Social Buttons',
    description:
      'With simple solution, customers can contact your shop faster with many tools on the same program to increase sales.',
    logo: recommendAppImage.CTA,
    href: 'https://apps.shopify.com/cta-buttons??utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
    label: 'Check now',
  },
  {
    title: 'Omega - Order Tracking',
    description: 'Track your orders with a branded tracking page & automatic notifications. Reduce WISMO support tickets.',
    logo: recommendAppImage.orderTracking,
    href: 'https://apps.shopify.com/omega-order-tracking?utm_source=ipblock&utm_medium=inapprcm&utm_campaign=partner',
    label: 'Check now',
  },
];
