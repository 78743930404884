import Layout from '@/components/layout';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { LegacyStack } from '@shopify/polaris';
import { memo, useEffect } from 'react';
import TablePricingPlan from './components/TablePricingPlan';

function PricingPlan() {
  const { newUser, userPlanShopifyPlus } = userPlans();

  const [fetchGA] = apiCaller.useFetchGAMutation();
  useEffect(() => {
    fetchGA({
      eventName: 'pricing_plan_impression',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      layoutProps={{
        title: 'Pricing plan',
        fullWidth: !newUser && userPlanShopifyPlus,
      }}
    >
      <LegacyStack vertical spacing="loose">
        <TablePricingPlan />
      </LegacyStack>
    </Layout>
  );
}

export default memo(PricingPlan);
