import { Card, Tabs } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import HeatMapBlocked from './Blocked';
import HeatMapVisited from './Visited';
const HeatMap = () => {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'visited',
      content: 'Allowed visitor heatmap',
      component: <HeatMapVisited />,
    },
    {
      id: 'blocked',
      content: 'Blocked visitor heatmap',
      component: <HeatMapBlocked />,
    },
  ];
  return (
    <Card padding={'100'}>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabs[selected].component}
      </Tabs>
    </Card>
  );
};
export default memo(HeatMap);
