import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import TooltipUpdateHigherPlan from '@/components/TooltipUpgradePlan/TooltipUpdateHigherPlan';
import { Enum } from '@/constants';
import { perPageOptions } from '@/constants/options';
import { dateToTimeStamp, truncateString } from '@/helpers';
import useCountry from '@/hooks/useCountry';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import blockedVisitorSlice, { blockHistorySelector } from '@/redux/slice/blockedVisitor.slice';
import visitorLogSlice, { allAccessSelector, perPageSelector, tabsTableSelector, visitorLogSelector } from '@/redux/slice/visitorAnalytics.slice';
import { ChoiceList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import options from '../../options';

const Filters = () => {
  const dispatch = useDispatch();
  const perPage = useSelector(perPageSelector);
  const allAccess = useSelector(allAccessSelector);
  const visitorLogTable = useSelector(visitorLogSelector);
  const { userPlanFree } = userPlans();
  const selected = useSelector(tabsTableSelector);
  const handleCountry = useCountry();
  const dataVisitor = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const dataBlocked = apiCaller.useFetchChartBlockQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  });
  const getProviders = apiCaller.useGetProvidersQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    typeList: selected === 0 ? Enum.TypeList.Allowed : selected === 1 ? Enum.TypeList.Blocked : undefined,
  });
  const getLocations = apiCaller.useGetLocationsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    typeList: selected === 0 ? Enum.TypeList.Allowed : selected === 1 ? Enum.TypeList.Blocked : undefined,
  });
  const providerFilterAll = getProviders.data?.data?.map((item: string) => ({ label: item, value: item }));
  const locationFilterAll = getLocations.data?.data?.map((item) => ({ label: item.name, value: item.name }));
  const countryFilterVisitor = handleCountry.listCountry.filter((item) => {
    return dataVisitor?.data?.data.heatMaps.map((item) => item.countryCode).includes(item.value);
  });
  const countryFilterBlock = handleCountry.listCountry.filter((item) => {
    return dataBlocked?.data?.data.heatMaps.map((item) => item.countryCode).includes(item.value);
  });

  const handleOnChangePerPage = (selected: string[]) => {
    dispatch(visitorLogSlice.actions.handlePerPage(selected[0]));
    dispatch(
      visitorLogSlice.actions.handleVisitorLogTable({
        ...visitorLogTable,
        page: 1,
      }),
    );
    dispatch(
      blockedVisitorSlice.actions.handleBlockHistoryTable({
        ...blockHistoryTable,
        page: 1,
      }),
    );
  };

  // Allowed
  const filtersVisitors = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[perPage || '']}
          onChange={handleOnChangePerPage}
        />
      ),
      shortcut: true,
    },
    {
      key: 'status',
      label: 'Status',
      shortcut: true,
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={options.statusOptions}
          selected={[visitorLogTable.status || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                status: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
    {
      key: 'location',
      label: 'Location',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={locationFilterAll || []}
          label={'Location'}
          selectedOptions={visitorLogTable.Locations || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                Locations: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'countriesCode',
      label: 'Country',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={countryFilterVisitor || []}
          label={'Country'}
          selectedOptions={visitorLogTable.countriesCode || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                countriesCode: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'risk',
      label: 'Risk level',
      shortcut: true,
      suffix: userPlanFree ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: userPlanFree,
      filter: (
        <ChoiceList
          title="Risk level"
          titleHidden
          choices={options.riskOptions}
          selected={[visitorLogTable.risk || '']}
          onChange={(value) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                risk: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
    {
      key: 'isp',
      label: 'ISP',
      shortcut: true,
      suffix: userPlanFree ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: userPlanFree,
      filter: (
        <AutoCompleteWithTag
          options={providerFilterAll || []}
          label={'ISP'}
          selectedOptions={visitorLogTable.providers || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              visitorLogSlice.actions.handleVisitorLogTable({
                ...visitorLogTable,
                providers: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
  ];

  const appliedFiltersVisitors = [
    {
      onRemove: () => {
        dispatch(visitorLogSlice.actions.handlePerPage('10'));
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            page: 1,
          }),
        );
      },

      key: 'perPage',
      label: 'Record per page: ' + perPage,
      hidden: perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            status: '',
            page: 1,
          }),
        ),
      key: 'status',
      label: `Status: ${options.statusOptions.find((item) => item.value === visitorLogTable.status)?.label || 'All'}`,
      hidden: !visitorLogTable.status,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'risk',
      label: `Risk level: ${options.riskOptions.find((item) => item.value === visitorLogTable.risk)?.label}`,
      hidden: !visitorLogTable.risk,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            countriesCode: [],
            page: 1,
          }),
        ),
      key: 'countriesCode',
      label: `Country: ${truncateString(
        countryFilterVisitor
          .filter((item) => visitorLogTable.countriesCode?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (visitorLogTable.countriesCode || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            providers: [],
            page: 1,
          }),
        ),
      key: 'isp',
      label: `ISP: ${truncateString(
        (providerFilterAll || [])
          .filter((item) => visitorLogTable.providers?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (visitorLogTable.providers || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          visitorLogSlice.actions.handleVisitorLogTable({
            ...visitorLogTable,
            Locations: [],
            page: 1,
          }),
        ),
      key: 'location',
      label: `Location: ${truncateString(
        (locationFilterAll || [])
          .filter((item) => visitorLogTable.Locations?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (visitorLogTable.Locations || '').length === 0,
    },
  ];

  // Blocked
  const blockHistoryTable = useSelector(blockHistorySelector);
  const filtersBlocked = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={perPageOptions}
          selected={[perPage]}
          onChange={handleOnChangePerPage}
        />
      ),
      shortcut: true,
    },
    {
      key: 'country',
      label: 'Country',
      filter: (
        <AutoCompleteWithTag
          options={countryFilterBlock}
          label={'Country'}
          selectedOptions={blockHistoryTable.countriesCode || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              blockedVisitorSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                countriesCode: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
      shortcut: true,
    },
    {
      key: 'location',
      label: 'Location',
      shortcut: true,
      filter: (
        <AutoCompleteWithTag
          options={locationFilterAll || []}
          label={'Location'}
          selectedOptions={blockHistoryTable.Locations || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              blockedVisitorSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                Locations: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
    },
    {
      key: 'isp',
      label: 'ISP',
      suffix: userPlanFree ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: userPlanFree,
      filter: (
        <AutoCompleteWithTag
          options={providerFilterAll || []}
          label={'ISP'}
          selectedOptions={blockHistoryTable.providers || []}
          setSelectedOptions={(selected: string[]) => {
            dispatch(
              blockedVisitorSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                providers: selected,
                page: 1,
              }),
            );
          }}
          allowMultiple={true}
        />
      ),
      shortcut: true,
    },
    {
      key: 'risk',
      label: 'Risk level',
      shortcut: true,
      suffix: userPlanFree ? <TooltipUpdateHigherPlan /> : undefined,
      disabled: userPlanFree,
      filter: (
        <ChoiceList
          title="Risk level"
          titleHidden
          choices={options.riskOptions}
          selected={[blockHistoryTable.risk || '']}
          onChange={(value) => {
            dispatch(
              blockedVisitorSlice.actions.handleBlockHistoryTable({
                ...blockHistoryTable,
                risk: value[0] || undefined,
                page: 1,
              }),
            );
          }}
        />
      ),
    },
  ];

  const appliedFiltersBlocked = [
    {
      onRemove: () => {
        dispatch(visitorLogSlice.actions.handlePerPage('10'));
        dispatch(
          blockedVisitorSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            page: 1,
          }),
        );
      },
      key: 'perPage',
      label: 'Record per page: ' + perPage,
      hidden: perPage === '10',
    },
    {
      onRemove: () =>
        dispatch(
          blockedVisitorSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            countriesCode: [],
            page: 1,
          }),
        ),
      key: 'country',
      label: `Country: ${truncateString(
        countryFilterBlock
          .filter((item) => blockHistoryTable.countriesCode?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (blockHistoryTable.countriesCode || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          blockedVisitorSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            providers: undefined,
            page: 1,
          }),
        ),
      key: 'isp',
      label: `ISP: ${truncateString(
        (providerFilterAll || [])
          .filter((item) => blockHistoryTable.providers?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (blockHistoryTable.providers || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          blockedVisitorSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            Locations: [],
            page: 1,
          }),
        ),
      key: 'location',
      label: `Location: ${truncateString(
        (locationFilterAll || [])
          .filter((item) => blockHistoryTable.Locations?.includes(item.value))
          ?.map((item) => item.label)
          .toString(),
        100,
      )}`,
      hidden: (blockHistoryTable.Locations || '').length === 0,
    },
    {
      onRemove: () =>
        dispatch(
          blockedVisitorSlice.actions.handleBlockHistoryTable({
            ...blockHistoryTable,
            risk: undefined,
            page: 1,
          }),
        ),
      key: 'risk',
      label: `Risk level: ${options.riskOptions.find((item) => item.value === blockHistoryTable.risk)?.label}`,
      hidden: !blockHistoryTable.risk,
    },
  ];

  return {
    visitors: {
      filters: filtersVisitors,
      appliedFilters: appliedFiltersVisitors,
    },
    Blocked: {
      filters: filtersBlocked,
      appliedFilters: appliedFiltersBlocked,
    },
  };
};

export default Filters;
